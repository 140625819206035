const actions = {
  //INIT_DATA: 'ECOMMERCE_INIT_DATA',
  INIT_DATA_SAGA: 'ECOMMERCE_INIT_DATA_SAGA',
  UPDATE_DATA: 'ECOMMERCE_UPDATE_DATA',
  UPDATE_DATA_SAGA: 'ECOMMERCE_UPDATE_DATA_SAGA',
  CHANGE_VIEW: 'ECOMMERCE_CHANGE_VIEW',
  VIEW_TOPBAR_CART: 'ECOMMERCE_VIEW_TOPBAR_CART',
  VIEW_TOPBAR_PAYMENT_METHODS: 'ECOMMERCE_VIEW_TOPBAR_PAYMENT_METHODS',
  VIEW_TOPBAR_USER_PROFILE: 'ECOMMERCE_VIEW_TOPBAR_USER_PROFILE',
  VIEW_TOPBAR_COMPANY_PROFILE: 'ECOMMERCE_VIEW_TOPBAR_COMPANY_PROFILE',
  VIEW_TOPBAR_CHECKOUT_PROCESSING: 'ECOMMERCE_VIEW_TOPBAR_CHECKOUT_PROCESSING',
  //initData: () => ({ type: actions.INIT_DATA_SAGA }),
  changeView: view => ({
    type: actions.CHANGE_VIEW,
    view
  }),
  changeViewTopbarCart: (viewTopbarCart) => {
    return {
      type: actions.VIEW_TOPBAR_CART,
      viewTopbarCart
    };
  },
  changeViewTopbarPaymentMethods: viewTopbarPaymentMethods => {
    return {
      type: actions.VIEW_TOPBAR_PAYMENT_METHODS,
      viewTopbarPaymentMethods
    };
  },
  changeViewTopbarUserProfile: viewTopbarUserProfile => {
    return {
      type: actions.VIEW_TOPBAR_USER_PROFILE,
      viewTopbarUserProfile
    };
  },
  changeViewTopbarCompanyProfile: viewTopbarCompanyProfile => {
    return {
      type: actions.VIEW_TOPBAR_COMPANY_PROFILE,
      viewTopbarCompanyProfile
    };
  },
  changeViewTopbarCheckoutProcessing: viewTopbarCheckoutProcessing => {
    return {
      type: actions.VIEW_TOPBAR_CHECKOUT_PROCESSING,
      viewTopbarCheckoutProcessing
    };
  },
  changeProductQuantity: productQuantity => {
    return (dispatch, getState) => {
      const { products } = getState().Ecommerce;
      dispatch({
        type: actions.UPDATE_DATA,
        products,
        productQuantity
      });
    };
  },
  addToCart: (product,quant) => {
    return (dispatch, getState) => {
      const { products, productQuantity } = getState().Ecommerce;
      const objectID = product.id;
      productQuantity.push({ objectID, quantity: quant });

      products[objectID] = product;
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        products,
        productQuantity
      });
    };
  },
  deleteCart: () => {
    return (dispatch) => {
      const productQuantity = [];
      const products = {};
      dispatch({
        type: actions.UPDATE_DATA,
        products,
        productQuantity
      });
    };
  }
};
export default actions;
