import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout, Menu  } from "antd";
import options from "./options";
import {
  HomeOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
  ControlOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  IdcardOutlined,
  TeamOutlined,
  KeyOutlined,
  LinkOutlined
} from '@ant-design/icons';
import Scrollbars from "../../components/utility/customScrollBar.js";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
//import Logo from "../../components/utility/logo";
//import logo from '../../image/heidi3.png';
//import logoKlein from '../../image/heidiKopf3.png';
const pic = {
  HomeOutlined: <HomeOutlined />,
  FileProtectOutlined: <FileProtectOutlined />,
  ScheduleOutlined: <ScheduleOutlined />,
  ControlOutlined: <ControlOutlined />,
  SettingOutlined: <SettingOutlined />,
  LinkOutlined: <LinkOutlined />,
  UserSwitchOutlined: <UserSwitchOutlined />,
  TeamOutlined: <TeamOutlined />,
  KeyOutlined: <KeyOutlined />,
  IdcardOutlined: <IdcardOutlined />
}
//const SubMenu = Menu.SubMenu;
const { SubMenu } = Menu;
//const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  //if (str.substr(-1) === "/") {
  //  return str.substr(0, str.length - 1);
  //}
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
/*    this.state = {
      logo: logo,
      logoKlein: logoKlein
    }*/
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
/*
  componentDidUpdate(prevProps) {
    // will be true
    const locationChanged =
      this.props.location !== prevProps.location;


  }
*/
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };

  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, companyType, userType,} = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="menuHolder" style={submenuColor}>
              {pic[leftIcon]}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    var linkToo =`${url}/${key}`;
    if (key === 'dashboard'){
      linkToo =`${url}`;
    }


    return (
      <Menu.Item key={key}>
        <Link to={linkToo}>
          <span className="menuHolder" style={submenuColor}>
            {pic[leftIcon]}
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const { app, toggleOpenDrawer, customizedTheme, height, locale, location } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";

  //  console.log('path',history);
  /*  const onMouseEnter = event => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    }; */


    //console.log('app.current',app.current);
    const site = stripTrailingSlash(location.pathname);
    var page = site.substring(site.lastIndexOf('/') + 1);
    var curPage = [ page ];

    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
    return (
<SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="sidebar"

          style={styling}
        >

          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="dashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={curPage}
              onOpenChange={this.onOpenChange}
            >
              {options.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
              {/* Demo Menu */}

            </Menu>
          </Scrollbars>
        </Sider>
</SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    customizedTheme: state.Auth.sidebarTheme,
    locale: state.LanguageSwitcher.language.locale,
    height: state.App.height
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
