import Auth from './auth/reducer';
import App from './app/reducer';
import Contacts from './contacts/reducer';
import Tickets from './tickets/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
//import Users from './users/reducers';
import Ecommerce from './ecommerce/reducer';
export default {
  Auth,
  App,
  Tickets,
  LanguageSwitcher,
  Contacts,

  Ecommerce
};
