import React from "react";
import { Route , Redirect , Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import App from "./containers/App/App";
import AppLanding from "./containers/AppLanding/AppLanding";
//import AppMember from "./containers/AppMember/AppMember";
import asyncComponent from "./helpers/AsyncFunc";
//import { loadReCaptcha } from 'react-recaptcha-google';
//loadReCaptcha();
const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedIn ? ( <Component {...props} />) : (<Redirect to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
//  loadReCaptcha();
/*
const RestrictedRouteMember = ({ component: Component, isLoggedInMember, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedInMember ? ( <Component {...props} />) : (<Redirect to={{
            pathname: "/shop",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);


  <RestrictedRouteMember
    path="/shopAuth"
    component={AppMember}
    isLoggedIn={isLoggedInMember}
    isLoggedInMember={isLoggedInMember}
  />
  
   <Route
          exact
          path={"/500"}
          component={asyncComponent(() => import("./containers/Page/500"))}
        />
        <Route
          exact
          path={"/404"}
          component={asyncComponent(() => import("./containers/Page/404"))}
        />
        <Route
          exact
          path={"/loading"}
          component={asyncComponent(() => import("./containers/Page/loading"))}
        />
        <Route
          exact
          path={"/link/"}
          component={asyncComponent(() => import("./containers/Page/link"))}
        />
  
  
  
  
  
  */
  return (

    <ConnectedRouter history={ history } >
      <div>
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/start"))}
          history={history}
        />
        <Route
          path="/admin"
          component={AppLanding}
          history={history}
        />
       
        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
          history={history}
        />
</div>

    </ConnectedRouter>

  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.isLoggedIn !== false,
}))(PublicRoutes);
