import ticketActions from './actions';

const initState = {
  eventId: '',
};

export default function ticketReducer(state = initState, action) {

  switch (action.type) {
    case ticketActions.CHANGE_EVENT_ID:
      return {
        ...state,
        eventId: action.recId
      };
    default:
      return state;
  }
}
