import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { LoginOutlined, MenuOutlined, UserAddOutlined } from '@ant-design/icons';
import { Layout, Tooltip, Drawer, Divider, PageHeader, Button, Input, Modal } from "antd";
import appActions from "../../redux/app/actions";
import TopbarWrapper from "./topbar.style";
import authAction from '../../redux/auth/actions';
import Signin from '../../containers/Page/signin';
import Signup from '../../containers/Page/signup';
import ForgotPassword from '../../containers/Page/forgotPassword';
import IntlMessages from '../../components/utility/intlMessages';
//import TopbarAddtoCart from './topbarAddtoCart';
import TopbarMenu from './topbarMenu';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import logo from '../../image/logo.png';
import logoDark from '../../image/logoDark.png';
import Flags from "../../settings/countries/flags";
const { Header } = Layout;
const { toggleCollapsed, view } = appActions;
const { toggleSignup, toggleLogin } = authAction;

class TopbarLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email:'fritz@aa.aa',
      password:'212121',
      buttdis:false,
      modalForgotPassword:false,
      logo: logo,
      logoDark: logoDark,
      visibleMenu: false,
    }
  }

  handleLogin = () => {
    this.props.toggleLogin(true)
    this.props.toggleSignup(false)
    this.setState({modalForgotPassword:false})
  }

  handleSignup = () => {
    this.props.toggleLogin(false)
    this.props.toggleSignup(true)
    this.setState({modalForgotPassword:false})
  }

  handleForgotPassword = () => {
    this.props.toggleLogin(false)
    this.props.toggleSignup(false)
    this.setState({modalForgotPassword:true})
  }

  closeLogin = () => {
    this.props.toggleLogin(false)
  }

  closeSignup = () => {
    this.props.toggleSignup(false)
  }

  closeForgotPassword = () => {
    this.setState({modalForgotPassword:false})
  }

  showMenu = () => {
    this.setState({
      visibleMenu: true,
    });
  };

  closeMenu = () => {
    this.setState({
      visibleMenu: false,
    });
  };
/*
  toggleSignup = () => {
    this.setState({modalSignup:false})
  }

  toggleForgotPassword = () => {
    this.setState({modalForgotPassword:false})
  }
*/
  render() {
    const { history, view ,customizedTheme ,locale ,country, logo, color, url, isLoggedInMember, modalSignup, modalLogin } = this.props; //url
    const { modalVisible , modalForgotPassword } = this.state;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      zIndex: 1,
      width: "100%",
      height: 70,
    };


    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={"Topbar"}

        >
          <div className="isoLeft">
            { view === "MobileView" &&
                    <TopbarMenu  />
            }
            { view === "MobileView" ?
              <Link to={`${url}`}>
                <img height={35} width="auto" src={this.state.logo} />
              </Link>

                :
              <Link to={`${url}`}>
                <img height={50} src={this.state.logo} />
              </Link>
            }
            &nbsp;&nbsp;





          </div>


          <ul className="isoRight" style={{  margin: "0px", padding: "0px" }}>
            { view !== "MobileView" &&
              <li>

                <LanguageSwitcher
                  color={customizedTheme.textColor}
                  size="20px"
                />
              </li>
            }

            <li onClick={this.handleSignup}  >
             <Tooltip placement="bottom" title="sign up">
              <UserAddOutlined style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
             </Tooltip>
            </li>
            <li onClick={this.handleLogin}  >
             <Tooltip placement="bottom" title="login">
              <LoginOutlined style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
             </Tooltip>
            </li>

          </ul>

          <Drawer
              width="70%"
              placement="left"
              closable={false}
              onClose={this.closeMenu}
              visible={this.state.visibleMenu}

            >
            <PageHeader onBack={this.closeMenu} title="Menu"  style={{  margin: "0px", padding: "0px" }}/>
            <Divider />
              <span style={{ fontSize: "16px" }}>Language: </span><LanguageSwitcher size="16px"/>
          </Drawer>

        </Header>



        {
          modalLogin &&
          <Modal
            visible={modalLogin}
            closable={false}
            width='350px'
            onClose={this.closeLogin}
            onCancel={this.closeLogin}
            footer={null}
          >
          <Signin history={history} handleSignup={this.handleSignup} handleForgotPassword={this.handleForgotPassword} logoDark={this.state.logoDark}/>
          </Modal>
        }
        {
          modalSignup &&
          <Modal
            visible={modalSignup}
            closable={false}
            width='550px'
            onClose={this.closeSignup}
            onCancel={this.closeSignup}
            footer={null}
          >
          <Signup history={history} handleLogin={this.handleLogin}  handleForgotPassword={this.handleForgotPassword} toggleLogin={this.toggleLogin} logoDark={this.state.logoDark}/>
          </Modal>
        }
        {
          modalForgotPassword &&
          <Modal
            visible={modalForgotPassword}
            closable={false}
            width='350px'
            onClose={this.closeForgotPassword}
            onCancel={this.closeForgotPassword}
            footer={null}
          >
          <ForgotPassword history={history} handleLogin={this.handleLogin} logoDark={this.state.logoDark}/>
          </Modal>
        }



      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    country: state.Auth.country || '',
    customizedTheme: state.Auth.topbarTheme,
    isLoggedInMember: state.Auth.isLoggedInMember,
    modalSignup: state.Auth.modalSignup,
    modalLogin: state.Auth.modalLogin
  }), { toggleSignup, toggleLogin }
)(TopbarLanding);
