import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import authAction from '../../redux/auth/actions';
import { Input, Row, Col, Divider, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import IntlMessages from '../../components/utility/intlMessages';
import { injectIntl } from 'react-intl';
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
const { logout } = authAction;
class ChangePassword extends Component {
  constructor(props){
      super(props);
      this.handlePW = this.handlePW.bind(this);
      this.state = {
        password1:'',
        password2:'',
      }
  }


  handlePW() {
      const { password1, password2} = this.state;
      const { formatMessage } = this.props.intl;
      if (!(password1 && password2)) {
        //notification('error', 'Please choose a password');
        notificationIntl('error','not.confirmPassword',formatMessage);
        return;
      }
      if (password1 !== password2) {
        //notification('error', 'Passwords are not identical');
        notificationIntl('error','not.unequalPassword',formatMessage);
        return;
      }
      var user = FirebaseHelper.firebaseCurrentUser();
      user.updatePassword(password1).then(function() {
        this.props.logout();
        //notification('success', 'Password changed succesfully. Please signin again.');
        notificationIntl('success','not.successPWchanged',formatMessage);
        //FirebaseHelper.logout();


      }.bind(this)).catch(function(error) {
        if (error) {
        const message = error.message;
        notification('error', message);
      };
      });

  }


  render() {
    const from = { pathname: "/signin" };
    const { redirectToReferrer } = this.state;
    const { locale } = this.props;
    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    //<PageHeader>Change Password</PageHeader>
    return (
                <div>
                 <p><IntlMessages id="page.resetPassDescription" /></p>
                  <Row>
                    <Col>
                      <div style={{ maxWidth: "250px" , paddingTop: "20px"}}>
                        <FormattedMessage id="forms.passwordNew">
                        { msg => <Input.Password
                          size="large"
                          type="password"
                          placeholder={msg}
                          value={this.state.password1}
                          onChange={event => this.setState({password1: event.target.value})}
                        />}
                        </FormattedMessage>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ maxWidth: "250px", paddingTop: "10px"}}>
                        <FormattedMessage id="forms.passwordConfirm">
                        { msg => <Input.Password
                          size="large"
                          type="password"
                          placeholder={msg}
                          value={this.state.password2}
                          onChange={event => this.setState({password2: event.target.value})}
                        />}
                        </FormattedMessage>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <div>
                      <Button type="primary" onClick={this.handlePW}  style={{ marginTop: "10px" }}>
                        <IntlMessages id="page.butSave" />
                      </Button>
                    </div>
                  </Row>
                </div>
        );
      }
    }




export default injectIntl(connect(
  state => ({
  ...state.Auth,
  locale: state.LanguageSwitcher.language.locale,
  }),
  { logout }
)(ChangePassword));
