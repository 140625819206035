import FirebaseHelper from "../../helpers/firebase/index";
import { push } from 'react-router-redux';


const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  CHANGE_AVATAR: 'CHANGE_AVATAR',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_SUCCESS_MEMBER: 'LOGIN_SUCCESS_MEMBER',
  TOGGLE_LOGIN: 'TOGGLE_LOGIN',
  TOGGLE_ADMIN: 'TOGGLE_ADMIN',
  TOGGLE_SIGNUP: 'TOGGLE_SIGNUP',
  ADD_USER_CREDENTIALS: 'ADD_USER_CREDENTIALS',
  FETCH_USER: 'FETCH_USER',
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_COUNTRY: 'CHANGE_COUNTRY',
  SET_COMPANY: 'SET_COMPANY',
  USER_ADDRESS: 'USER_ADDRESS',

  login: () => {
    let data = null;
    return function(dispatch, getState) {
      //var id = getState().Auth.user[5];
      //var a  = { is_online: true };
      //FirebaseHelper.firebaseUpdateDocAttribute('users', id , a);
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: { data },
      });

    };
  },

  loginMember: () => {
    let data = null;
    return function(dispatch) {
      dispatch({
        type: actions.LOGIN_SUCCESS_MEMBER,
        payload: { data },
      });

    };
  },

  changeAvatar: (url) => ({
    type: actions.CHANGE_AVATAR,
    url
  }),

  changeUserAddress: (userAddress) => ({
    type: actions.USER_ADDRESS,
    userAddress
  }),

  setCompany: (company) => ({
    type: actions.SET_COMPANY,
    company
  }),

  changeCountry: () => {
  //  console.log('country triggered');
    return async function(dispatch) {
      const response = await fetch(`https://europe-west1-cloudgate-35502.cloudfunctions.net/geolocationWebhook`)
        if (response.ok) {
         let result = await response.json();
      //    console.log('country', result.country,'ip', result.userIP,);
          dispatch({
          type: actions.CHANGE_COUNTRY,
          country: result.country
          });
        } else {
         console.log("HTTP-Error: ",response.status);
        }
      }
  },

  checkAuthorization: () => {
    return function(dispatch) {
    const userKey = Object.keys(sessionStorage)
    .filter(it => it.startsWith('firebase:authUser'))[0];
    const authuser = userKey ? JSON.parse(sessionStorage.getItem(userKey)) : undefined;
    dispatch(actions.changeCountry());

    if (authuser) {
      console.log('Authorisation:','auto');
    //dispatch(push('/loading'));

    let user = {};
    let company = {};
    let uid = '';
    FirebaseHelper.firebaseGetDoc('users', authuser.uid)
    .then(doc => {
      if (doc.exists) {
         user = doc.data();
         uid = doc.id;


              //  console.log(company.type)
              //  console.log(user.firstname)
              //    dispatch(actions.changeCountry());

                  if (user.is_admin) {
                    dispatch(actions.toggleAdmin());
                  }
                
                  //this.props.changeAvatar(user.avatar);
                  dispatch(actions.changeUserAddress(user));
                //  dispatch(actions.changeAvatar(user.avatar));
                  dispatch(actions.addUserCredentials(user.company.name));
                  dispatch(actions.addUserCredentials(user.firstname));
                  dispatch(actions.addUserCredentials(user.lastname));
                  dispatch(actions.addUserCredentials(user.email));
                  dispatch(actions.addUserCredentials(user.cid));
                  dispatch(actions.addUserCredentials(uid));        // 5
                  dispatch(actions.addUserCredentials(user.account));
                  dispatch(actions.setCompany(user.company));
                  dispatch(actions.login());
                  dispatch(push('/dashboard'));

      }
      });

      }
      else {

        console.log('Authorisation:','default');

        }
    }
  },

  logout: () => {
    return function(dispatch, getState) {
      //var id = getState().Auth.user[5];
      //var a  = { is_online: false };
/*      FirebaseHelper.firebaseUpdateDocAttribute('users', id , a)
      .then(function() {
*/
      dispatch({ type: actions.LOGOUT });
      FirebaseHelper.logout();
/*    }).catch(function(error) {
        if (error) {
         const message = error.message;
         console.log('error:',error.message);
           }
      });
*/    }
  },

  logoutMember: () => {
    return function(dispatch) {

      dispatch({ type: actions.LOGOUT });
      FirebaseHelper.logout();
    }
  },

  toggleAdmin: () => ({
    type: actions.TOGGLE_ADMIN
  }),

  toggleMember: () => ({
    type: actions.TOGGLE_MEMBER
  }),

  toggleSignup: (value) => ({
    type: actions.TOGGLE_SIGNUP,
    bool: value
  }),

  toggleLogin: (value) => ({
    type: actions.TOGGLE_LOGIN,
    bool: value
  }),

  addUserCredentials: (value) => ({
    type: actions.ADD_USER_CREDENTIALS,
    payload: value //payload: { data },
  }),

  fetchUser: () => ({
    type: actions.FETCH_USER
  }),
/*
  changeTheme: (attribute, themeName, docId) => {
    var a  = { [attribute]: themeName };
    FirebaseHelper.firebaseUpdateDocAttribute('companies', docId, a);

    const theme = getCurrentTheme(attribute, themeName);

    if (attribute === 'layoutTheme') {
    //  console.log('layoutTheme2',theme.backgroundColor);
      document.getElementsByClassName('isomorphicContent')[0].style.backgroundColor = theme.backgroundColor;
    }
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme
    };
  },

  changeThemeStart: (attribute, themeName) => {
    const theme = getCurrentTheme(attribute, themeName);

    if (attribute === 'layoutTheme') {
    //  console.log('layoutTheme2',theme.backgroundColor);
      document.getElementsByClassName('isomorphicContent')[0].style.backgroundColor = theme.backgroundColor;
    }
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme
    };
  }
*/
};
export default actions;
