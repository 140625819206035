import { notification } from 'antd';


const createNotification = (type, id, formatMessage) => {
  let description = '';
  let message = formatMessage({id: id});
  notification[type]({
    message,
    description,
  });
};
export default createNotification;
