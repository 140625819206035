
const config = {
  options: [
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      value: 'en',
      label: 'english',
    },
    {
      languageId: 'german',
      locale: 'de',
      text: 'German',
      value: 'de',
      label: 'deutsch',
    },
    {
      languageId: 'french',
      locale: 'fr',
      text: 'French',
      value: 'fr',
      label: 'français'
    },
    {
      languageId: 'italian',
      locale: 'it',
      text: 'Italian',
      value: 'it',
      label: 'italiana'
    },
    {
       languageId: 'spanish',
       locale: 'es',
       text: 'Spanish',
       value: 'es',
       label: 'espagnol'
     },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.locale === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
