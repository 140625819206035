import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
//import IntlMessages from '../../components/utility/intlMessages';
import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip, Drawer, Divider, Popover, PageHeader } from 'antd';
import authAction from '../../redux/auth/actions';
import ecommerceAction from '../../redux/ecommerce/actions';
//import TopbarDropdownWrapper from './topbarDropdown.style';
import DrawerUserProfile from '../../containers/UserProfile/userProfile';
import DrawerCompanyProfile from '../../containers/CompanyProfile/companyProfile';
//import DrawerAvatar from '../../containers/Avatar/avatar';
import DrawerChangePassword from '../../containers/ChangePassword/changePassword';
//import DrawerPaymentMethods from '../../containers/PaymentMethods/paymentMethods';
import DrawerChangeEmail from '../../containers/ChangeEmail/changeEmail';
import Menu from './menu';

const { logoutMember } = authAction;
const { changeViewTopbarPaymentMethods, changeViewTopbarUserProfile, changeViewTopbarCompanyProfile } = ecommerceAction;


class TopbarUser extends Component {

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.showTicketboxSub = this.showTicketboxSub.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    //  visibleUserProfile: false,
      visibleAvatar: false,
      visibleChangePassword: false,
      visibleChangeEmail: false,
    //  visiblePaymentMethod: false

    };
  }

  showUserProfile = () => {
      this.props.changeViewTopbarUserProfile(true);
      this.setState({ visible: false });
    };
  closeUserProfile = () => {
      this.props.changeViewTopbarUserProfile(false);
    };
  showCompanyProfile = () => {
      this.props.changeViewTopbarCompanyProfile(true);
      this.setState({ visible: false });
    };
  closeCompanyProfile = () => {
      this.props.changeViewTopbarCompanyProfile(false);
    };
  showAvatar = () => {
      this.setState({
        visibleAvatar: true,
      });
      this.setState({ visible: false });
    };
  closeAvatar = () => {
      this.setState({
        visibleAvatar: false,
      });
    };
  showChangePassword = () => {
      this.setState({
        visibleChangePassword: true,
      });
      this.setState({ visible: false });
    };
  closeChangePassword = () => {
      this.setState({
        visibleChangePassword: false,
      });
    };
  showChangeEmail = () => {
      this.setState({
        visibleChangeEmail: true,
      });
      this.setState({ visible: false });
    };
  closeChangeEmail = () => {
      this.setState({
        visibleChangeEmail: false,
      });
    };
  showPaymentMethod = () => {
      this.props.changeViewTopbarPaymentMethods(true);
      this.setState({ visible: false });
    };
  closePaymentMethod = () => {
      this.props.changeViewTopbarPaymentMethods(false);
    };
  showTicketboxSub() {
     this.props.showTicketbox();
     this.setState({ visible: false });
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  handleLogout = () => {
    this.props.logoutMember();
  }
//showAvatar={this.showAvatar}
  render() {
  const { profilePicture, customizedTheme, userFirstName, userLastName, uid, viewPaymentMethods, viewUserProfile, viewCompanyProfile, view } = this.props;
  let draw = '60%';
  let place = 'right';
  if (view === "MobileView") {
    draw = '90%';
    place = 'left';
  }

    const content = (

      <Menu
        showUserProfile={this.showUserProfile}
        showCompanyProfile={this.showCompanyProfile}
        showChangeEmail={this.showChangeEmail}
        showPaymentMethod={this.showPaymentMethod}
        showChangePassword={this.showChangePassword}
        handleLogout={this.handleLogout}
        showTicketboxSub={this.showTicketboxSub}
        />


    );
/*
<Drawer
    width="60%"
    placement="right"
    closable={false}
    onClose={this.closeAvatar}
    visible={this.state.visibleAvatar}
  >
  <FormattedMessage id="menu.menu">
  { msg => <PageHeader onBack={this.closeAvatar} title="Avatar" style={{  marginLeft: "0px", paddingLeft: "0px" }} />}
  </FormattedMessage>
  <Divider />
  <DrawerAvatar />
</Drawer>
*/

    return (
      <div>
      { view == 'DesktopView' ? (
      <FormattedMessage id="menu.menu">
      { msg => <Tooltip placement="bottom" title={msg}>
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
      <div>
        { view === "MobileView" ? (
          <div>
            <MenuOutlined style={{ color: customizedTheme.textColor, cursor: "pointer", fontSize: "25px", marginRight: "10px", marginTop: "25px" }} />
          </div>
        ):(
          <div>
                 {
                   profilePicture === '' ?
                   <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                     <div style={{ paddingRight: "5px", width: '30px' }}>
                      <UserOutlined style={{ color: customizedTheme.textColor, cursor: "pointer", fontSize: "25px", marginRight: "10px", marginTop: "0px" }}/>
                     </div>
                     <div style={{ paddingTop: "5px" }}>
                       <h5 style={{ color: customizedTheme.textColor }}>{userFirstName}<br />{userLastName}</h5>
                     </div>
                   </div>
                    :
                   <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                     <div style={{ width: '34px' }}>
                       <Avatar size={32} src={this.props.profilePicture} />
                     </div>
                     <div style={{ marginLeft: "5px", paddingTop: "5px" }}>
                       <h5 style={{ color: customizedTheme.textColor }}>{userFirstName}<br />{userLastName}</h5>
                     </div>
                   </div>
                 }
          </div>
        )}
        </div>
      </Popover>
      </Tooltip>}
      </FormattedMessage>
    ):(
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
      <div>
        { view === "MobileView" ? (
          <div style={{ paddingLeft: '5px' }}>
            <MenuOutlined style={{ color: customizedTheme.textColor, cursor: "pointer", fontSize: "25px", marginRight: "10px", marginTop: "25px" }} />
          </div>
        ):(
          <div>
                 {
                   !profilePicture ?
                   <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                     <div style={{ paddingRight: "5px", width: '30px' }}>
                      <UserOutlined style={{ color: customizedTheme.textColor, cursor: "pointer", fontSize: "25px", marginRight: "10px", marginTop: "0px" }}/>
                     </div>
                     <div style={{ paddingTop: "5px" }}>
                       <h5 style={{ color: customizedTheme.textColor }}>{userFirstName}<br />{userLastName}</h5>
                     </div>
                   </div>
                    :
                   <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                     <div style={{ width: '34px' }}>
                       <Avatar size={32} src={this.props.profilePicture} />
                     </div>
                     <div style={{ marginLeft: "5px", paddingTop: "5px" }}>
                       <h5 style={{ color: customizedTheme.textColor }}>{userFirstName}<br />{userLastName}</h5>
                     </div>
                   </div>
                 }
          </div>
        )}
        </div>
      </Popover>
      )}
      <Drawer
          width={draw}
          placement={place}
          closable={false}
          onClose={this.closeUserProfile}
          visible={viewUserProfile}
        >
        <FormattedMessage id="menu.userProfile">
        { msg => <PageHeader onBack={this.closeUserProfile} title={msg} style={{  marginLeft: "0px", paddingLeft: "0px" }} />}
        </FormattedMessage>
        <Divider />
        <DrawerUserProfile />
        </Drawer>

        <Drawer
            width={draw}
            placement={place}
            closable={false}
            onClose={this.closeCompanyProfile}
            visible={viewCompanyProfile}
          >
          <FormattedMessage id="menu.companyProfile">
          { msg => <PageHeader onBack={this.closeCompanyProfile} title={msg} style={{  marginLeft: "0px", paddingLeft: "0px" }} />}
          </FormattedMessage>
          <Divider />
          <DrawerCompanyProfile />
          </Drawer>


        <Drawer
            width={draw}
            placement={place}
            closable={false}
            onClose={this.closeChangePassword}
            visible={this.state.visibleChangePassword}
          >
          <FormattedMessage id="menu.changePassword">
          { msg => <PageHeader onBack={this.closeChangePassword} title={msg} style={{  marginLeft: "0px", paddingLeft: "0px" }} />}
          </FormattedMessage>
          <Divider />
          <DrawerChangePassword />
        </Drawer>

        <Drawer
            width={draw}
            placement={place}
            closable={false}
            onClose={this.closeChangeEmail}
            visible={this.state.visibleChangeEmail}
          >
          <FormattedMessage id="menu.changeEmail">
          { msg => <PageHeader onBack={this.closeChangeEmail} title={msg} style={{  marginLeft: "0px", paddingLeft: "0px" }} />}
          </FormattedMessage>
          <Divider />
          <DrawerChangeEmail />
        </Drawer>


      </div>
    );
  }
}

/*
        <Drawer
            width={draw}
            placement={place}
            closable={false}
            onClose={this.closePaymentMethod}
            visible={viewPaymentMethods}
          >
          <FormattedMessage id="menu.paymentMethods">
          { msg => <PageHeader onBack={this.closePaymentMethod} title={msg} style={{  marginLeft: "0px", paddingLeft: "0px" }} />}
          </FormattedMessage>
          <Divider />
          <DrawerPaymentMethods uid={uid}/>
        </Drawer>
*/
function mapStateToProps(state) {
  //const { profilePicture } = state.Auth;
  return {
  //  user,
    profilePicture: state.Auth.profilePicture,
    customizedTheme: state.Auth.topbarTheme,
    profilePicture: state.Auth.profilePicture,
    userFirstName: state.Auth.userAddress.firstname,
    userLastName: state.Auth.userAddress.lastname,
    uid: state.Auth.user[5],
    viewPaymentMethods: state.Ecommerce.viewTopbarPaymentMethods,
    viewUserProfile: state.Ecommerce.viewTopbarUserProfile,
    viewCompanyProfile: state.Ecommerce.viewTopbarCompanyProfile
  };
}
export default connect(mapStateToProps,{
  logoutMember, changeViewTopbarPaymentMethods, changeViewTopbarUserProfile, changeViewTopbarCompanyProfile
})(TopbarUser);
