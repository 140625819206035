import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";//, Redirect
//import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import { MenuOutlined } from '@ant-design/icons';
import { Avatar, Tooltip, Drawer, Row, Col, Divider, Popover} from 'antd';
import { PageHeader } from 'antd';
import authAction from '../../redux/auth/actions';
import ecommerceAction from '../../redux/ecommerce/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import DrawerUserProfile from '../../containers/UserProfile/userProfile';
import DrawerAvatar from '../../containers/Avatar/avatar';
import DrawerChangePassword from '../../containers/ChangePassword/changePassword';
//import DrawerPaymentMethods from '../../containers/PaymentMethods/paymentMethods';
import DrawerChangeEmail from '../../containers/ChangeEmail/changeEmail';
import Menu from './menu';

const { logoutMember } = authAction;
const { changeViewTopbarPaymentMethods, changeViewTopbarUserProfile } = ecommerceAction;


class TopbarUser extends Component {

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    //  visibleUserProfile: false,
      visibleAvatar: false,
      visibleChangePassword: false,
      visibleChangeEmail: false,
    //  visiblePaymentMethod: false

    };
  }

  showUserProfile = () => {
      this.props.changeViewTopbarUserProfile(true);
      this.setState({ visible: false });
    };
  closeUserProfile = () => {
      this.props.changeViewTopbarUserProfile(false);
    };

  showAvatar = () => {
      this.setState({
        visibleAvatar: true,
      });
      this.setState({ visible: false });
    };
  closeAvatar = () => {
      this.setState({
        visibleAvatar: false,
      });
    };
  showChangePassword = () => {
      this.setState({
        visibleChangePassword: true,
      });
      this.setState({ visible: false });
    };
  closeChangePassword = () => {
      this.setState({
        visibleChangePassword: false,
      });
    };
  showChangeEmail = () => {
      this.setState({
        visibleChangeEmail: true,
      });
      this.setState({ visible: false });
    };
  closeChangeEmail = () => {
      this.setState({
        visibleChangeEmail: false,
      });
    };
  showPaymentMethod = () => {
      this.props.changeViewTopbarPaymentMethods(true);
      this.setState({ visible: false });
    };
  closePaymentMethod = () => {
      this.props.changeViewTopbarPaymentMethods(false);
    };

  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  handleLogout = () => {

      this.props.logoutMember();

      }

  render() {
  const { profilePicture, customizedTheme, userFirstName, userLastName, uid, viewPaymentMethods, viewUserProfile } = this.props;


    const content = (
      <Menu
        showUserProfile={this.showUserProfile}
        showAvatar={this.showAvatar}
        showChangeEmail={this.showChangeEmail}
        showPaymentMethod={this.showPaymentMethod}
        showChangePassword={this.showChangePassword}
        handleLogout={this.handleLogout}
      />
    );

    return (
      <div>

      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div>
          <MenuOutlined style={{ color: customizedTheme.textColor, cursor: "pointer", fontSize: "25px", marginRight: "10px", marginTop: "25px" }} />
        </div>
      </Popover>


      <Drawer
          width="60%"
          placement="right"
          closable={false}
          onClose={this.closeUserProfile}
          visible={viewUserProfile}
        >
        <PageHeader onBack={this.closeUserProfile} title="User Profile" style={{  marginLeft: "0px", paddingLeft: "0px" }} />
        <Divider />
        <DrawerUserProfile />
      </Drawer>

      <Drawer
          width="60%"
          placement="right"
          closable={false}
          onClose={this.closeAvatar}
          visible={this.state.visibleAvatar}
        >
        <PageHeader onBack={this.closeAvatar} title="Avatar" style={{  marginLeft: "0px", paddingLeft: "0px" }} />
        <Divider />
        <DrawerAvatar />
      </Drawer>

        <Drawer
            width="60%"
            placement="right"
            closable={false}
            onClose={this.closeChangePassword}
            visible={this.state.visibleChangePassword}
          >
          <PageHeader onBack={this.closeChangePassword} title="Change Password" style={{  marginLeft: "0px", paddingLeft: "0px" }} />
          <Divider />
          <DrawerChangePassword />
        </Drawer>

        <Drawer
            width="60%"
            placement="right"
            closable={false}
            onClose={this.closeChangeEmail}
            visible={this.state.visibleChangeEmail}
          >
          <PageHeader onBack={this.closeChangeEmail} title="Change Email" style={{  marginLeft: "0px", paddingLeft: "0px" }} />
          <Divider />
          <DrawerChangeEmail />
        </Drawer>


      </div>
    );
  }
}
/*
        <Drawer
            width="60%"
            placement="right"
            closable={false}
            onClose={this.closePaymentMethod}
            visible={viewPaymentMethods}
          >
          <PageHeader onBack={this.closePaymentMethod} title="Payment Methods" style={{  marginLeft: "0px", paddingLeft: "0px" }} />
          <Divider />
          <DrawerPaymentMethods uid={uid}/>
        </Drawer>
*/





function mapStateToProps(state) {
  //const { profilePicture } = state.Auth;
  return {
  //  user,
    profilePicture: state.Auth.profilePicture,
    customizedTheme: state.Auth.topbarTheme,
    userFirstName: state.Auth.user[1],
    userLastName: state.Auth.user[2],
    uid: state.Auth.user[5],
    viewPaymentMethods: state.Ecommerce.viewTopbarPaymentMethods,
    viewUserProfile: state.Ecommerce.viewTopbarUserProfile
  };
}
export default connect(mapStateToProps,{
  logoutMember, changeViewTopbarPaymentMethods, changeViewTopbarUserProfile
})(TopbarUser);
