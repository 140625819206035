import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { push } from 'react-router-redux';
import { connect } from "react-redux";
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';
import { Input, Divider, Avatar, Button, Alert } from "antd";
import authAction from "../../redux/auth/actions";
import appAction from "../../redux/app/actions";
import IntlMessages from "../../components/utility/intlMessages";
import { FormattedMessage, injectIntl } from 'react-intl';
import FirebaseHelper from "../../helpers/firebase/index";
import { notification } from '../../components/index';
import notificationIntl from '../../components/uielements/notificationIntl';
import SocialMediaIcons from '../../settings/socialMedia/icons';
const { login, addUserCredentials, changeUserAddress, changeAvatar, toggleLogin, setCompany } = authAction;
const { clearMenu } = appAction;
class SignIn extends Component {
  constructor(props){
      super(props);
      this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
      this.verifyCallback = this.verifyCallback.bind(this);
      this.triggerSignup = this.triggerSignup.bind(this);
      this.triggerForgotPassword = this.triggerForgotPassword.bind(this);
      this.handleLogin = this.handleLogin.bind(this);
      this.state = {
        email:'',
        password:'',
        buttdis: false,
        buttdis2: false,
        socialMedia: false,
        stripeUpdateLink:'',
        accountPayoutDisabled: false,
      }
  }
  state = {
    redirectToReferrer: false
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn === true
    ) {
      this.setState({ redirectToReferrer: true });
    }
  }
  handleLogin = (provider) => {
  const { formatMessage } = this.props.intl;

  let userCred;
  let userId;

    if (provider == 'password') {
      const { email, password } = this.state;

      if (!(email && password)) {
        notification('error', 'Input email and password');
        return;
      }
    }
    this.setState({ buttdis: true });
    (async() => {
      const { socialMedia } = this.state;

      if (provider == 'password') {
        const { email, password } = this.state;
        const { locale } = this.props;
        userCred = await FirebaseHelper.firebaseLogin(email,password)

          .catch(error => {
            const message = error.message;
            notification('error', message);
            this.setState({buttdis: false });
            return;
          })

          .then(result => {

            if (result) {
              //console.log('result',result.user.emailVerified);
              if (result.user.emailVerified == false) {
                FirebaseHelper.sendEmailVerification(this.props.locale)
                .then(() => {
                   FirebaseHelper.logout();
                   this.props.toggleLogin();
                   notificationIntl('info','action.sendEmailVerification',formatMessage);
                   this.setState({buttdis: false });

                 }).catch((error) => {
                   FirebaseHelper.logout();
                   this.props.toggleLogin();
                   notificationIntl('info','action.sendEmailVerification',formatMessage);
                   this.setState({buttdis: false });

                 });

               return '';
             } else {

               return {  uid: result.user.uid,
                       email: result.user.email,
                       photoURL : ''
                      };
               }
            }
          });
     }

        if (userCred) {

                    let user = {};
                    let company = {};
                    var abcd = await FirebaseHelper.firebaseGetDoc('users', userCred.uid)
                      .then(doc => {
                        if (!doc.exists) {
                          notification('error', 'No administrator account with your credentials found.');
                          FirebaseHelper.logout();
                          this.setState({buttdis: false });
                        }  else {
        //                console.log('user logged in');

                         let user = doc.data();

                         if ( user.email !== userCred.email) {
                           FirebaseHelper.firebaseUpdateDocAttribute('users', userCred.uid, { email: userCred.email });
                           user.email = userCred.email;
                         }

                         let userId = doc.id;

                         if (user.type !== 'Admin') {
                           notification('error', 'Only administators are allowed to sign in!');
                           FirebaseHelper.logout();
                           this.setState({buttdis: false });
                         } else {
                           if (user.account) {

                              let a = { account: user.account};

                              async function postData(url = '', data = {}) {
                                // Default options are marked with *
                                const response = await fetch(url, {
                                  method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                  mode: 'cors', // no-cors, *cors, same-origin
                                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                  credentials: 'omit', // include, *same-origin, omit
                                  headers: { 'Content-Type': 'application/json'
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                  },
                                  redirect: 'follow', // manual, *follow, error
                                  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                                  body: JSON.stringify(data) // body data type must match "Content-Type" header
                                });
                                return response.json(); // parses JSON response into native JavaScript objects
                              }

                              postData('https://europe-west1-cloudgate-35502.cloudfunctions.net/HttpAccountInfo', a)
                                .then(data => {
                                  //console.log(data); // JSON data parsed by `data.json()` call

                                  if (data) {

                                    this.setState({buttdis: false });
                                    let account = data;

                                    /*
                                    if (!account.payouts_enabled) {

                                      //console.log('payouts_enabled', account.payouts_enabled);

                                      this.setState({stripeUpdateLink: account.link });
                                      this.setState({accountPayoutDisabled: true });
                                      FirebaseHelper.logout();
                                      return;
                                    }
                                    */
                                    let b = 0;
                                    if (account.payouts_enabled) {
                                      b = 1;
                                    } else {
                                      b = 2;
                                    }
                                    let a={};

                                    this.props.changeUserAddress(user);
                                    this.props.addUserCredentials(user.company.name);    // 0
                                    this.props.addUserCredentials(user.firstname);      // 1
                                    this.props.addUserCredentials(user.lastname);       // 2
                                    this.props.addUserCredentials(user.email);          // 3
                                    this.props.addUserCredentials(user.cid);            // 4
                                    this.props.addUserCredentials(userId);              // 5
                                    //console.log('user.account', user.account);
                                    this.props.addUserCredentials(user.account);        // 6
                                    this.props.addUserCredentials(b);   // 7
                                    this.props.addUserCredentials(account.link || '');        // 8
                                    //console.log('user.company', user.company);
                                    this.props.setCompany(user.company);


                                    this.props.login();

                                    push("/dashboard");
                                    this.props.toggleLogin();
                                  } else {
                                    notification('error', 'No Stripe account DATA found! Please contact support.');
                                    FirebaseHelper.logout();
                                    this.setState({buttdis: false });
                                  }

                                });

                              } else {
                                notification('error', 'No Stripe account found! Please contact support.');
                                FirebaseHelper.logout();
                                this.setState({buttdis: false });
                              }

                         }

                      }
                    })
                    .catch(err => {
                     console.log('catch 2',err);
                     notification('error', err);
                     FirebaseHelper.logout();
                     this.setState({buttdis: false });
                     return;
                     });


              } else {
                // notification('error', 'No user id found.');
                this.setState({buttdis: false });
                return;

              }
      })();


      //  });
    };



  componentDidMount() {

    if (this.captchaDemo) {
        console.log("started, just a second...")
        this.captchaDemo.reset();
        this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
        this.captchaDemo.reset();
        this.captchaDemo.execute();
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token")
  }


  triggerSignup() {
    this.props.handleSignup();
  }
  triggerForgotPassword() {
    this.props.handleForgotPassword();
  }

  handleStripeLink() {
    //this.setState({buttdis2: true });
    const { stripeUpdateLink } = this.state;
    console.log("stripeUpdateLink", stripeUpdateLink);
    window.location.href = stripeUpdateLink;
  }

  render() {
    const from = { pathname: "/dashboard" };
    const { redirectToReferrer, accountPayoutDisabled, stripeUpdateLink } = this.state;
    const { logoDark } = this.props;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
/*
    <Button
     size="delfault"
     type="default"
     onClick={event => this.handleStripeLink()}
     loading={this.state.buttdis2}
     >
     Stripe
    </Button>
*/



    return (
      <div>
        <div>
            <img height={40} src={logoDark} />
        </div>
        <Divider style={{ marginTop: "15px", marginBottom: "15px" }}/>
          {    accountPayoutDisabled ?
            (
              <div style={{  marginTop: "10px" }}>
                <Alert message="Your Stripe account is not fully verified: Payouts are disabled." type="info" showIcon/>
                <div style={{  marginTop: "10px" }}>
                  <a target="_self"  href={stripeUpdateLink} >
                    <Button
                      type="primary"
                      size="large"
                      onClick={event => this.setState({buttdis2: true})}
                      loading={this.state.buttdis2}
                      block
                      >
                    Connect/Check with Stripe
                    </Button>
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <Input
                    value={this.state.email}
                    size="large"
                    placeholder="Email"
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange={event => this.setState({email: event.target.value})}
                    onPressEnter={event => this.handleLogin('password')}
                    />
                </div>
                <div style={{ marginTop: "5px" }}>
                  <Input.Password
                    value={this.state.password}
                    size="large" type="password"
                    placeholder="Password"
                    prefix={<UnlockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange={event => this.setState({password: event.target.value})}
                    onPressEnter={event => this.handleLogin('password')}
                    />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={event => this.handleLogin('password')}
                    loading={this.state.buttdis}
                    disabled={this.state.buttdis2} block>
                    <IntlMessages id="page.signInButton" />
                  </Button>
                </div>
              </div>

            )
          }


            <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
             <div >


            <label>
              <IntlMessages id="page.signInCreateAccount" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignup}>
              <IntlMessages id="page.signInCreateAccountLink" />
            </label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <label>
              <IntlMessages id="page.signInForgotPass" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerForgotPassword}>
              <IntlMessages id="page.signInForgotPassLink" />
            </label>
          </div>
        </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    ...state.Auth,

    locale: state.LanguageSwitcher.language.locale,
  }),
  { toggleLogin, login, addUserCredentials, changeUserAddress, clearMenu, changeAvatar, setCompany }
)(SignIn));
