import React, { Component }  from 'react';
import { connect } from "react-redux";
import Avatar from 'react-avatar-edit';
import FirebaseHelper from "../../helpers/firebase/index";
import authAction from '../../redux/auth/actions';
import LayoutWrapper from '../../components/utility/layoutWrapper.js';
import LayoutContent from "../../components/utility/layoutContent";
//import PageHeader from '../../components/utility/pageHeader';
import { Empty, Row, Col, Divider, Button, PageHeader } from 'antd';
import { notification } from '../../components/index';
const { changeAvatar } = authAction;

class AvatarPic extends Component {

  constructor(props) {
    super(props)
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onClick = this.onClick.bind(this)
    this.resetAvatar = this.resetAvatar.bind(this)
      this.state = {
      preview: null,
      buttdis: '',

  }

  }

  resetAvatar() {
    const { uid, avatar, isLoggedIn, isLoggedInMember } = this.props;

    if (avatar) {
        this.setState({buttdis: 'disabled'});
        var storageRef = FirebaseHelper.firebaseStorageRef();
        var avatarRef = storageRef.child('images/avatars/' + uid + '_avatar');
        // Delete the file

        avatarRef.delete().then(function() {
          let a = {avatar: '' };
          //  let b = {merge: true};

          // member or user
          if (isLoggedInMember) {
            FirebaseHelper.firebaseUpdateDocAttribute('members', uid, a);
          } else if (isLoggedIn) {
            FirebaseHelper.firebaseUpdateDocAttribute('users', uid, a);
          }

          let c = '';
          //console.log('change to', c);
          this.props.changeAvatar(c);
          notification('success', 'Avatar removed');
          this.setState({buttdis: ''});
        }.bind(this)).catch(function(error) {
          if (error) {
            const message = error.message;
            notification('error', message);
            }
          // Uh-oh, an error occurred!
        });
     } else {
       notification('info','Avatar not defined');
     }

  }

  onClose() {
    this.setState({preview: null});
    this.setState({size: null})
  }

  onCrop(preview) {
    this.setState({preview});
  }

  onClick(event) {
    //event.preventDefault();
    const { preview } = this.state;
    const { uid, isLoggedIn, isLoggedInMember } = this.props;
    if (!preview){return;}
    this.setState({buttdis: 'disabled'});
    var storageRef = FirebaseHelper.firebaseStorageRef();
    var avatarRef = storageRef.child('images/avatars/' + uid + '_avatar');
    var log = avatarRef.fullPath;
  //  console.log(log);
  //  console.log(uid);
    avatarRef.putString(preview, 'data_url').then(function(snapshot) {
      this.setState({buttdis: 'disabled'});
  //  console.log('Uploaded avatar!');

  //      let mauser = avatarRef.getDownloadURL();
  //      let mausi = mauser.i;

  //      console.log('testresult',mausi);

        avatarRef.getDownloadURL().then(function(downloadURL) {
        let a = {avatar: downloadURL };

      //  member or user
        if (isLoggedInMember) {
          FirebaseHelper.firebaseUpdateDocAttribute('members', uid, a);
        } else if (isLoggedIn) {
          FirebaseHelper.firebaseUpdateDocAttribute('users', uid, a);
        }

        let c = downloadURL;
        this.props.changeAvatar(c);
        notification('success', 'Avatar saved');
        this.setState({buttdis: ''});
        //  console.log('change to', c);

      }.bind(this));
    }.bind(this));
  //
  }

  render () {
    const { uid } = this.props;
    //style={{ position: "absolute", right: "0px" }}
    //<PageHeader>Avatar</PageHeader>
    return (
<div>
  <Row type="flex" justify="space-around">
    <Col xs={24} sm={24} md={12} lg={12} xl={{ span: 8}}>

            <Avatar
              width="100%"
              height={295}
              onCrop={this.onCrop}
              onClose={this.onClose}
              src={this.state.src}
            />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={{ span: 8}}>
          <div style={{
            height: "100%",
            display: "flex",
            position: "relative",
            width: "100%",

            margin: "20px",

            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",

           }}>
            {
              this.state.preview? <img src={this.state.preview} height="120" alt="Preview" />
            :
              <div></div>
            }

        </div>
    </Col>
  </Row>
  <Row>
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
     <Divider />
    </Col>
  </Row>
  <Row>
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ position: "relative", marginTop: "20px" }}>
                  <Button type="primary" onClick={this.onClick} disabled={this.state.buttdis} >
                    Save
                  </Button>
                  <Button style={{ marginLeft: "8px" }} type="secondary" onClick={this.resetAvatar} disabled={this.state.buttdis} >
                    Remove Avatar
                  </Button>

                </div>
   </Col>
 </Row>


</div>
    );
  }



}



export default connect(
  state => ({
    ...state.Auth,
    uid: state.Auth.user[5],
    avatar: state.Auth.profilePicture,
    isLoggedIn: state.Auth.isLoggedIn,
    isLoggedInMember: state.Auth.isLoggedInMember,
  }),
  { changeAvatar }
)(AvatarPic);
