import React, { Component } from 'react';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import { Menu } from 'antd';
import IntlMessages from '../../components/utility/intlMessages';
//const { SubMenu } = Menu;


export default class extends Component {
  render() {
    const { showUserProfile, showCompanyProfile, showChangeEmail, showChangePassword, handleLogout } = this.props;
    //  <Menu.Item key="6" onClick={showAvatar} >Change Avatar</Menu.Item>
    return (
      <Menu
        onClick={this.handleClick}
        style={{ width: 256 }}
        mode="inline"
      >

            <Menu.Item key="1" onClick={showUserProfile} ><IntlMessages id="menu.userProfile" /></Menu.Item>
            <Menu.Item key="2" onClick={showCompanyProfile} ><IntlMessages id="menu.companyProfile" /></Menu.Item>
            <Menu.Item key="3" onClick={showChangePassword} ><IntlMessages id="menu.changePassword" /></Menu.Item>
            <Menu.Item key="4" onClick={showChangeEmail} ><IntlMessages id="menu.changeEmail" /></Menu.Item>
            <Menu.Item key="5" ><IntlMessages id="menu.language" />: <LanguageSwitcher /></Menu.Item>
            <Menu.Item key="6" onClick={handleLogout} ><IntlMessages id="menu.logout" /></Menu.Item>

      </Menu>
    )
  }
}
