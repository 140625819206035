import React, { Component } from 'react';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import { Menu } from 'antd';
//<Menu.Item key="1" onClick={handleLogout} >Sign In</Menu.Item>
export default class extends Component {
  render() {
    const { handleLogout } = this.props;
    return (
      <Menu
        onClick={this.handleClick}
        style={{ width: 256 }}
        mode="inline"
      >

        <Menu.Item key="2" >Language: <LanguageSwitcher /></Menu.Item>
      </Menu>
    )
  }
}
