import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';




class AppLandingRouter extends Component {

  render() {
    const { url, location, style, isLoggedInMember, stripe, history } = this.props;

    return (
      <div style={style}>
        <Route
          exact={true}
          key="admin"
          path={`${url}`}
          url={url}
          component={asyncComponent(() => import('../Page/landing'))}
        />
        
        <Route
          exact={true}
          key="404"
          path={`${url}/404`}
          component={asyncComponent(() => import('../Page/404'))}
        />
      </div>
    );
  }
}

export default AppLandingRouter
 //connect nicht erlaubt!!!!
