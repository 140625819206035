//import fakeData from "../../containers/Contacts/fakeData";
import contactActions from "./actions";

//const contacts = new fakeData(8).getAll();

const initState = {
  contacts: [],
  seectedId: '',
  editView: false,
  message: {
    receiverUid: '',
    senderFirstName: '',
    senderLastName: '',
    senderUid: '',
    senderProfilePicture: '',
    message: '',
    created_at: new Date().getTime(),
  },
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case contactActions.LOAD_CONTACTS:
      return {
        ...state,
          contacts: action.contacts,
          seectedId: action.seectedId
      };
    case contactActions.CHANGE_CONTACT:
      return {
        ...state,
        seectedId: action.id,
        editView: false
      };
    case contactActions.ADD_CONTACT:
      return {
        ...state,
        contacts: action.contacts,
        seectedId: action.seectedId,
        editView: false
      };
    case contactActions.EDIT_CONTACT:
      return {
        ...state,
        contacts: action.contacts
      };
    case contactActions.DELETE__CONTACT:
      return {
        ...state,
        contacts: action.contacts,
        seectedId: action.seectedId
      };
    case contactActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };
    default:
      return state;
  }
}

//export { contacts };
