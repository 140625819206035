import mastercard from '../../image/paymentMethods/card_mastercard.svg';
import visa from '../../image/paymentMethods/card_visa.svg';
import amex from '../../image/paymentMethods/card_american_express.svg';
import sepa from '../../image/paymentMethods/card_sepa.svg';
import google from '../../image/paymentMethods/gpay-logo.svg';
import apple from '../../image/paymentMethods/card_apple_pay.svg';
import microsoft from '../../image/paymentMethods/card_microsoft_pay.png';
import ideal from '../../image/paymentMethods/ideal.png';
import pci from '../../image/paymentMethods/pci.png';
import stripe from '../../image/paymentMethods/powered_by_stripe.png';

var paymentIcons =  {}
                paymentIcons.mastercard = mastercard;
                paymentIcons.visa = visa;
                paymentIcons.amex = amex;
                paymentIcons.sepa = sepa;
                paymentIcons.ideal = ideal;
                paymentIcons.google = google;
                paymentIcons.microsoft = microsoft;
                paymentIcons.apple = apple;
                paymentIcons.pci = pci;
                paymentIcons.stripe = stripe; 

export default paymentIcons;
