import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Avatar, Typography } from "antd";
import StartHelper from "../../components/config/startHelperUser";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import "./dashAppStyle.css";
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import authAction from '../../redux/auth/actions';
//import Logo from "../../components/utility/logo";
import logo from '../../image/logo.png';
import logoKlein from '../../image/icon.png';
import "./dashAppStyle.css";
const { Header } = Layout;
const { toggleCollapsed, view } = appActions;
const { logout } = authAction;
const { Title } = Typography;
class Topbar extends Component {



  constructor(props) {
    super(props);

    this.state = {
      logo: logo,
      logoKlein: logoKlein
    }

  }



/*
<Button size="small" >Sign out</Button>

  <i
    className="ion-log-out"
    style={{ color: customizedTheme.textColor }}
  />
*/


  handleLogout = () => {
      //FirebaseHelper.logout();
      this.props.logout();

      }


  render() {
    const { companyName , userFirstName , userLastName , toggleCollapsed , view , customizedTheme , locale , logo , profilePicture, url } = this.props; //url
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

    return (
      <TopbarWrapper>
        <StartHelper />
        <Header
          style={styling}
          className={
            collapsed ? "topbar collapsed" : "topbar collapsed"
          }
        >

          <div className="isoLeft">
            { collapsed ?
              <Link to={`${url}`}>
                <img height={50} width="auto" src={this.state.logoKlein} />
              </Link>
                :
              <Link to={`${url}`}>
                <img height={50} src={this.state.logo} />
              </Link>
            }
            &nbsp;&nbsp;
            {
              collapsed ?
              <MenuUnfoldOutlined
                onClick={toggleCollapsed}
                style={{ color: customizedTheme.textColor, fontSize: "25px", paddingTop: "3px", paddingLeft: "25px" }}
              />
              :
              <MenuFoldOutlined
                onClick={toggleCollapsed}
                style={{ color: customizedTheme.textColor, fontSize: "25px", paddingTop: "3px", paddingLeft: "25px" }}
              />

            }
            &nbsp;&nbsp;
          <Title level={4} style={{ color: customizedTheme.textColor, fontSize: "25px", paddingTop: "20px", paddingLeft: "25px" }}>{companyName}</Title>
          </div>
          <ul className="isoRight">
            <li>
              <LanguageSwitcher
                color={customizedTheme.textColor}
                size="20px"
              />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser locale={locale} />

            </li>
            <li onClick={this.handleLogout}>
              <LogoutOutlined
                onClick={this.handleLogout}
                style={{ color: customizedTheme.textColor, fontSize: "25px" }} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.Auth.topbarTheme,
    companyName: state.Auth.user[0],
    userFirstName: state.Auth.user[1],
    userLastName: state.Auth.user[2],
    profilePicture: state.Auth.profilePicture,
    logo: state.Auth.logo,

  }),
  { toggleCollapsed , logout}
)(Topbar);
