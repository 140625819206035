import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input, Checkbox, Divider, Row, Col, Avatar, Button, Select, Steps, message } from 'antd';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import FirebaseHelper from "../../helpers/firebase/index";
import { notification } from '../../components/index';
import IntlMessages from '../../components/utility/intlMessages';
import SocialMediaIcons from '../../settings/socialMedia/icons';
import Countries from '../../settings/countries/forms';
const { login, logout, addUserCredentials, changeAvatar } = authAction;
const { clearMenu } = appActions;
const { Option } = Select;
const { Step } = Steps;

const steps = [
{
  title: 'About',
},
{
  title: 'Business Information',
},
{
  title: 'Personal Information',
},
//{
//  title: 'Connect Stripe Account',
//},
];

class SignUp extends Component {
  constructor(props){
    super(props);
    this.triggerSignin = this.triggerSignin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleRegisterCompany = this.handleRegisterCompany.bind(this);
    this.singleOption = this.singleOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleCompanyType = this.handleCompanyType.bind(this);

    //console.log('ipcountry', this.props.ipCountry);
    let def = {};
    Countries.map(data => {
      if (this.props.ipCountry == data.code){
        def = data;
      }
    });
    this.state = {
      buttdis: false,
      current: 0,
      isIndividual: false,
      companyTypeCapital: 'Company',
      firstname:'',
      lastname:'',
      address:'',
      address2:'',
      city:'',
      zip: '',
      state: '',
      country: def.name,
      countryFlag: def.flag,
      countryCode: def.code,
      email:'',
      phone:'',
      cphone: def.dial_code,
      password:'',
      password2:'',
      companyType: '',
      companyName:'',
      companyVenue:'',
      companyAddress:'',
      companyAddress2:'',
      companyCity:'',
      companyZip: '',
      companyState: '',
      companyCountry: def.name,
      companyCountryFlag: def.flag,
      companyCountryCode: def.code,
      companyEmail: '',
      companyPhone: '',
      companyCphone: def.dial_code,
      companyWebsite:'',
      checkbox: false,
      socialMedia: false,

     }
    }

    next() {
      const current = this.state.current + 1;
      this.setState({ current });
    }

    prev() {
      const current = this.state.current - 1;
      this.setState({ current });
    }

    handleRegisterCompany() {

      const  { companyName, companyAddress, companyCity, companyZip, companyEmail, companyPhone } = this.state;

      if (!(companyName && companyAddress && companyCity && companyZip && companyEmail && companyPhone)) {
        notification('error', 'Please fill out all required fields.');
        return;
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
       }
      if (validateEmail(companyEmail) == false) {
        notification('error', 'Please provide a valid email address.');
        return;

      }
      this.next();
    }

   handleRegister(provider) {
   let res;

      const  { checkbox , firstname , lastname , address , city , zip , state, country , countryCode, email , phone, password , password2, showPassword } = this.state;

      if ( checkbox === false ) {
        notification('error', 'You have to accept the terms and conditions.');
        return;
        }
      if (!(firstname && lastname && address && city && zip && country)) {
        notification('error', 'Please fill out all required fields.');
        return;
        }
    if (provider == 'password') {

      if (!email) {
        notification('error', 'Please provide an email address.');
        return;
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
       }
      if (validateEmail(email) == false) {
        notification('error', 'Please provide a valid email address.');
        return;

      }
      if (!(password && password2)) {
        notification('error', 'Please define a password.');
        return;
      }
      if (password != password2) {
        notification('error', 'Passwords are different.');
        return;
      }
    }

    (async() => {

        const  { checkbox , firstname , lastname , address , address2, city , zip , state, country, countryCode, email , phone, cphone, password, buttdis } = this.state;
        const  { companyType, companyName, companyVenue, companyAddress, companyAddress2, companyCity, companyZip, companyState, companyCountry, companyCountryFlag, companyCountryCode, companyEmail, companyPhone, companyCphone, companyWebsite } = this.state;
              const capitalize = (s) => {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
              }

              const user= {};
              user.firstname = capitalize(firstname);
              user.lastname = capitalize(lastname);
              user.address = capitalize(address);
              user.address2 = capitalize(address2) || '';
              user.city = capitalize(city);
              user.zip = zip;
              user.state = state;
              user.country = capitalize(country);
              user.countryCode = countryCode;
              user.email = email;
              user.phone = phone;
              user.phonePre = cphone;
              user.company = {
                type: companyType,
                name: capitalize(companyName),
                venue: companyVenue || '',
                address: capitalize(companyAddress),
                address2: companyAddress2  || '',
                city: capitalize(companyCity),
                zip: companyZip,
                state: capitalize(companyState)  || '',
                country: companyCountry,
                countryFlag: companyCountryFlag,
                countryCode: companyCountryCode,
                email: companyEmail,
                phone: companyPhone,
                phonePre: companyCphone,
                website: companyWebsite  || '',
              };
              user.avatar = '';
              user.pm = []
              user.created_at = new Date().getTime();
              const a = user.firstname + ' ' + user.lastname;
            /*  const b = {
               "address": {
                  "line1": user.address,
                  "line2": user.address2 || '',
                  "city": user.city,
                  "postal_code": user.zip,
                  "country": user.country
               },
               "email": user.email,
               "metadata": '',
               "name": a,
               "phone": user.phone,
               };*/
        if (provider == 'password') {
            res = await FirebaseHelper.firebaseCreateUser(email,password)
                .then(result => {
                  //if (result) {
                    const uid = result.user.uid;
                    //const c = { "user": user, "a": b };
                    user.cid = result.user.uid;
                    const c = { "user": user };
                  //  console.log('uid',uid,'payload',c);
                    return { uid: uid, payload: c };
                //  }
                })
                .catch(result => {
                  const message = result.message
                  notification('error', message);
                  this.setState({ buttdis: false });
                  return;
                });
        }

        if (res) {
        //  console.log('res ',res);
        this.setState({ buttdis: true });

        FirebaseHelper.firebaseInsertDocSubDoc('admin_public',res.uid,'create_user',res.uid,res.payload)
        .then(function() {
                 //this.setState({ recordId: docRef.id });
                FirebaseHelper.firebase().collection('admin_public').doc(res.uid).collection('account_link').doc(res.uid)
                  .onSnapshot({
                      includeMetadataChanges: this.state.buttdis
                  }, function(doc) {


                    if (doc.exists) {
                      this.setState({buttdis: false });
                      let user = doc.data();
                      console.log('link', user.accountLink);
                      FirebaseHelper.firebaseDeleteEntireDocSUB('admin_public',res.uid,'create_user',res.uid);
                      FirebaseHelper.firebaseDeleteEntireDocSUB('admin_public',res.uid,'account_link',res.uid);
                      this.props.logout();

                      window.location.href = user.accountLink;

                    }

                  }.bind(this));
              }.bind(this));
        }
    })();

}

  triggerSignin() {
    this.props.handleLogin();
  }

  singleOption(valueObj,index) {
    return (<Option key={index} value={index }>{valueObj.flag}&nbsp;&nbsp;{valueObj.name}</Option>);
    }

  handleChange(value) {
    this.setState({ country: Countries[value].name });
    this.setState({ cphone: Countries[value].dial_code });
    this.setState({ countryFlag: Countries[value].flag });
    this.setState({ countryCode: Countries[value].code });
  //  console.log('country', Countries[value].name,Countries[value].dial_code,Countries[value].flag,Countries[value].code);
    }

  handleChangeCompany(value) {
    this.setState({ companyCountry: Countries[value].name });
    this.setState({ companyCphone: Countries[value].dial_code });
    this.setState({ companyCountryFlag: Countries[value].flag });
    this.setState({ companyCountryCode: Countries[value].code });
    this.setState({ country: Countries[value].name });
    this.setState({ cphone: Countries[value].dial_code });
    this.setState({ countryFlag: Countries[value].flag });
    this.setState({ countryCode: Countries[value].code });
  //  console.log('country', Countries[value].name,Countries[value].dial_code,Countries[value].flag,Countries[value].code);
    }

  handleCompanyType(value) {
    this.setState({ companyType: value.toLowerCase() });
    this.setState({ companyTypeCapital: value });
      if (value == 'Individual') {
        this.setState({ isIndividual: true });
      } else {
        this.setState({ isIndividual: false });
      }
    }

  render() {
    const { history, logoDark } = this.props;
    const { showPassword, current } = this.state;

    return (
         <div>
            <div>
              <img height={40} src={logoDark} />
            </div>
            <Divider />
              <Steps direction="vertical" current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                  ))}
              </Steps>
              <Divider />
              <div className="steps-content">
                {
                  current === 0
                  && <div>
                  <h3>Prerequisites</h3>
                  <p>During the registration process, you are going to open an account with us and then with Stripe. We recommend using the same administrator credentials for both accounts.
                   Make sure you have all relevant company data at hand before you start the process.<br />
                 Your Stripe account must be fully verified in order to use our service. After initial account creation, you have to login to Stripe (https://www.stripe.com) and provide additional informations. You can check your verification status in the Stripe dashboard.
                  </p>
                  <Divider />
                    <Button type="primary" onClick={() => this.next()}>
                      Next
                    </Button>
                  </div>
                }
                {

                  current === 1
                  && <div>
                  <h3>Business Information (Organizer)</h3>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Select
                        size="large"
                        defaultValue={this.state.companyTypeCapital}
                        defaultActiveFirstOption={false}
                        style={{ width: '100%' }}
                        onChange={this.handleCompanyType}
                        >
                        <Option key={1} value="Company">Company</Option>
                        <Option key={2} value="Individual">Individual</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Input  size="large" placeholder="Name" value={this.state.companyName} onChange={event => this.setState({companyName: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Input  size="large" placeholder="Venue/Location (optional)" value={this.state.companyVenue} onChange={event => this.setState({companyVenue: event.target.value})}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="Address line 1" value={this.state.companyAddress} onChange={event => this.setState({companyAddress: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="Address line 2 (optional)" value={this.state.companyAddress2} onChange={event => this.setState({companyAddress2: event.target.value})}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="Postal code" value={this.state.companyZip} onChange={event => this.setState({companyZip: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="City" value={this.state.companyCity} onChange={event => this.setState({companyCity: event.target.value})}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Select
                        size="large"
                        defaultValue={this.state.companyCountryFlag + '  ' + this.state.companyCountry}
                        defaultActiveFirstOption={false}
                        style={{ width: '100%' }}
                        onChange={this.handleChangeCompany}
                        >
                        {Countries.map((country, index) => this.singleOption(country, index))}
                      </Select>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Input.Group size="large">
                        <Row gutter={1}>
                          <Col span={8}>
                            <Input   size="large"

                              placeholder="Code"
                              value={this.state.companyCphone}
                              onChange={event => this.setState({companyCphone: event.target.value})}
                              />
                          </Col>
                          <Col span={16}>
                            <Input   size="large" placeholder="Support phone" value={this.state.companyPhone} onChange={event => this.setState({companyPhone: event.target.value})}/>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input   size="large" placeholder="Support email" value={this.state.companyEmail} onChange={event => this.setState({companyEmail: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input   size="large" placeholder="Website (optional)" value={this.state.companyWebsite} onChange={event => this.setState({companyWebsite: event.target.value})}/>
                    </Col>
                  </Row>

                  <Divider />
                    <Button
                      type="primary"
                      onClick={() => this.handleRegisterCompany()}
                      loading={this.state.buttdis}
                      >
                      Next
                    </Button>
                    {current > 0 && (
                      <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                        Previous
                      </Button>
                    )}
                  </div>
                }
                {

                  current === 2
                  && <div>
                  <h3>Personal Information (Administrator)</h3>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Input  size="large" placeholder="First name" value={this.state.firstname} onChange={event => this.setState({firstname: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Input  size="large" placeholder="Last name" value={this.state.lastname} onChange={event => this.setState({lastname: event.target.value})}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="Address line 1" value={this.state.address} onChange={event => this.setState({address: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="Address line 2 (optional)" value={this.state.address2} onChange={event => this.setState({address2: event.target.value})}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="Postal code" value={this.state.zip} onChange={event => this.setState({zip: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input  size="large" placeholder="City" value={this.state.city} onChange={event => this.setState({city: event.target.value})}/>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Select
                        size="large"
                        defaultValue={this.state.countryFlag + '  ' + this.state.country}
                        defaultActiveFirstOption={false}
                        style={{ width: '100%' }}
                        onChange={this.handleChange}
                        disabled={this.state.isIndividual}
                        >
                        {Countries.map((country, index) => this.singleOption(country, index))}
                      </Select>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Input.Group size="large">
                        <Row gutter={1}>
                          <Col span={8}>
                            <Input   size="large"

                              placeholder="Code"
                              value={this.state.cphone}
                              onChange={event => this.setState({cphone: event.target.value})}
                              />
                          </Col>
                          <Col span={16}>
                            <Input   size="large" placeholder="Mobile" value={this.state.phone} onChange={event => this.setState({phone: event.target.value})}/>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input   size="large" placeholder="Email" value={this.state.email} onChange={event => this.setState({email: event.target.value})}/>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input.Password  type="password"  size="large" placeholder="Password" value={this.state.password} onChange={event => this.setState({password: event.target.value})}/>
                    </Col>
                    <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Input.Password size="large" type="password" placeholder="Confirm Password" value={this.state.password2} onChange={event => this.setState({password2: event.target.value})}/>
                    </Col>
                  </Row>
                  <div  style={{ marginTop: "10px" }}>
                    <Checkbox checked={this.state.checkbox} onChange={event => this.setState({checkbox: !this.state.checkbox})}>
                      <label><IntlMessages id="page.signUpTC1" /></label><a><IntlMessages id="page.signUpTC2" /></a><label><IntlMessages id="page.signUpTC3" /></label><a><IntlMessages id="page.signUpTC4" /></a>.
                    </Checkbox>
                  </div>
                  <Divider />
                    <Button
                      type="primary"
                      onClick={event => this.handleRegister('password')}
                      loading={this.state.buttdis}
                      >
                      Connect with Stripe
                    </Button>
                    {current > 0 && (
                      <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                        Previous
                      </Button>
                    )}
                  </div>
                }

              </div>
              <div className="steps-action">



          </div>



              <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>


                <label>
                  <IntlMessages id="page.signUpAlreadyAccount" />&nbsp;
                </label>
                <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignin}>
                  <IntlMessages id="page.signUpAlreadyAccountLink" />
                </label>

            </div>
    );
  }
}

export default connect(
  state => ({
    locale: state.LanguageSwitcher.language.locale,
    ipCountry: state.Auth.country,
  }),
  { login, logout, addUserCredentials, changeAvatar }
)(SignUp);
