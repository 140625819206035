import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Row, Col, Typography, Input, Select, Button } from "antd";
import { EditOutlined } from '@ant-design/icons';
import FirebaseHelper from "../../helpers/firebase/index";
import IntlMessages from '../../components/utility/intlMessages';
import { FormattedMessage,  injectIntl } from 'react-intl';
import notification from '../../components/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
import Countries from '../../settings/countries/forms';
import authAction from "../../redux/auth/actions";
import clone from 'clone';
const { Title } = Typography;
const { Option } = Select;
const { changeUserAddress } = authAction;

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.saveBillingAddress = this.saveBillingAddress.bind(this);
    this.savePhone = this.savePhone.bind(this);
    this.singleOption = this.singleOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    const { member } = this.props;
    let def = {};
    Countries.map(data => {
      if (member.company.countryCode === data.code){
        def = data;
      }
    });
    this.state = {

      visibleUserProfile: false,
      editAddressBilling: false,
      editPhone: false,
      loading: false,
      companyName: member.company.name,
      address: member.company.address,
      address2: member.company.address2,
      city: member.company.city,
      zip: member.company.zip,
      state: member.company.state,
      country: def.name,
      countryFlag: def.flag,
      countryCode: def.code,
      phone: member.company.phone,
      phonePre: member.company.phonePre,
      supportEmail: member.company.email
    }
  }

  saveBillingAddress() {
    const { formatMessage } = this.props.intl;
    const { companyName, address, address2, city, zip, state, country, countryCode  } = this.state;
    const { member } = this.props;
    if (!(companyName && address && city && zip && country)) {
      //notification('error', 'Please fill out all required fields.');
      notificationIntl('error','not.fillAllFields',formatMessage);
      return;
      }
    this.setState({ loading: true });
    let a = clone(member);
    a.company.name = companyName;
    a.company.address = address;
    a.company.address2 = address2 || '';
    a.company.city = city;
    a.company.zip = zip;
    a.company.state = state || '';
    a.company.country = country;
    a.company.countryCode = countryCode;

    FirebaseHelper.firebaseInsertDoc('users',this.props.uid,a);
    this.props.changeUserAddress(a);
    this.setState({ loading: false });
    this.setState({ editAddressBilling: false });
  }

  savePhone() {
    const { formatMessage } = this.props.intl;
    const { phonePre, phone, supportEmail } = this.state;
    const { member } = this.props;
    if (!(phonePre && phone)) {
      //notification('error', 'Please fill out all required fields.');
      notificationIntl('error','not.fillAllFields',formatMessage);
      return;
      }
    this.setState({ loading: true });
    let a = clone(member);
    a.company.phone = phone;
    a.company.phonePre = phonePre;
    a.company.email = supportEmail;

    FirebaseHelper.firebaseInsertDoc('users',this.props.uid,a);
    this.props.changeUserAddress(a);
    this.setState({ loading: false });
    this.setState({ editPhone: false });
  }

  handleChange(value) {
      this.setState({ country: Countries[value].name });
      this.setState({ countryFlag: Countries[value].flag });
      this.setState({ countryCode: Countries[value].code });
      //this.setState({ phonePre: Countries[value].dial_code });
  }

  closeUserProfile = () => {
    this.setState({
      visibleUserProfile: false,
    });
    };

  singleOption(valueObj,index) {
    return (<Option key={index} value={index }>{valueObj.flag}&nbsp;&nbsp;{valueObj.name}</Option>);
    }

/*
<Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
  <FormattedMessage id="forms.lastname">
  { msg => <Input  size="large" placeholder={msg} value={this.state.lastname} onChange={event => this.setState({lastname: event.target.value})}/>}
</FormattedMessage>
</Col>
*/

render() {
const { member, locale } = this.props;
const { editAddressBilling, editPhone } =this.state;
return (
<div>

  <Row>
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <div style={{  display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center' }}>
        <Title level={4}><IntlMessages id="checkout.companyAddress" /></Title>
        <span
          style={{ marginLeft: '10px' , cursor: 'pointer', fontSize: '18px', paddingBottom: '5px' }}
          onClick={() => this.setState({ editAddressBilling: !this.state.editAddressBilling})}
          >
            <EditOutlined />
        </span>
      </div>
      { editAddressBilling ?
      (
        <div  style={{ maxWidth: "400px" }}>
        <Row gutter={5}>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormattedMessage id="forms.companyName">
            { msg => <Input  size="large" placeholder={msg} value={this.state.companyName} onChange={event => this.setState({companyName: event.target.value})}/>}
          </FormattedMessage>
          </Col>

        </Row>
        <Row>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormattedMessage id="forms.address">
            { msg => <Input  size="large" placeholder={msg} value={this.state.address} onChange={event => this.setState({address: event.target.value})}/>}
          </FormattedMessage>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormattedMessage id="forms.address2">
            { msg => <Input  size="large" placeholder={msg} value={this.state.address2} onChange={event => this.setState({address2: event.target.value})}/>}
          </FormattedMessage>
          </Col>
        </Row>
        <Row gutter={5}>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormattedMessage id="forms.zip">
            { msg => <Input  size="large" placeholder={msg} value={this.state.zip} onChange={event => this.setState({zip: event.target.value})}/>}
          </FormattedMessage>
          </Col>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormattedMessage id="forms.city">
            { msg => <Input  size="large" placeholder={msg} value={this.state.city} onChange={event => this.setState({city: event.target.value})}/>}
          </FormattedMessage>
          </Col>
        </Row>
        <Row gutter={5}>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
            <FormattedMessage id="forms.state">
            { msg => <Input  size="large" placeholder={msg} value={this.state.state} onChange={event => this.setState({state: event.target.value})}/>}
          </FormattedMessage>
          </Col>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={12} xl={12}>
            <Select
              size="large"
              defaultValue={this.state.countryFlag + '  ' + this.state.country}
              defaultActiveFirstOption={false}
              style={{ width: '100%' }}
              onChange={this.handleChange}
              >
              {Countries.map((country, index) => this.singleOption(country, index))}
            </Select>
          </Col>
        </Row>
        <Row>
         <Col>
          <Button
            type='primary'
            size='default'
            style={{ marginTop: "30px" }}
            onClick={this.saveBillingAddress}
            loading={this.state.loading}
            >
           <IntlMessages id="page.butSave" />
          </Button>
         </Col>
        </Row>
        </div>
      ) : (
        <div>
          { member.address2 ?
            (
            <p>
             {member.company.name}<br />
             {member.company.address}<br />
             {member.company.address2}<br />
             {member.company.zip} {member.company.city}<br />
             {member.company.state || ''}
                {member.company.state && <br />}
             {member.company.country}

            </p>
            ) : (
            <p>
             {member.company.name}<br />
             {member.company.address}<br />
             {member.company.zip} {member.company.city}<br />
             {member.company.state || ''}
                {member.company.state && <br />}
             {member.company.country}
            </p>
            )
          }
        </div>
      )

      }
    </Col>
  </Row>
  <Divider />
  <Row>
    <Col>
      <div style={{  display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center' }}>
      <Title level={4}><IntlMessages id="checkout.supportContacts" /></Title>
        <span
          style={{ marginLeft: '10px' , cursor: 'pointer', fontSize: '18px', paddingBottom: '5px' }}
          onClick={() => this.setState({ editPhone: !this.state.editPhone})}
        >
          <EditOutlined />
        </span>
      </div>
    { editPhone ?
      (
        <div  style={{ maxWidth: "400px" }}>
        <Row gutter={5}>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Input.Group size="large">
              <Row gutter={1}>
                <Col span={8}>
                  <FormattedMessage id="forms.phonePre">
                  { msg => <Input
                    size="large" placeholder={msg} value={this.state.phonePre} onChange={event => this.setState({phonePre: event.target.value})}
                    />}
                  </FormattedMessage>
                </Col>
                <Col span={16}>
                  <FormattedMessage id="forms.mobile">
                  { msg => <Input   size="large" placeholder={msg} value={this.state.phone} onChange={event => this.setState({phone: event.target.value})}/>}
                </FormattedMessage>
                </Col>
              </Row>
            </Input.Group>
          </Col>
        </Row>
        <Row gutter={5}>
          <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormattedMessage id="forms.supportEmail">
            { msg => <Input  type="email" size="large" placeholder={msg} value={this.state.supportEmail} onChange={event => this.setState({supportEmail: event.target.value})}/>}
          </FormattedMessage>
          </Col>
        </Row>
        <Row>
         <Col>
          <Button
            type='primary'
            size='default'
            style={{ marginTop: "30px" }}
            onClick={this.savePhone}
            loading={this.state.loading}
            >
           <IntlMessages id="page.butSave" />
          </Button>
         </Col>
        </Row>
        </div>
      ) : (
        <div>
         <p>
           {member.company.phonePre} {member.company.phone}
           <br />
           {member.company.email}
         </p>
        </div>
      )}
    </Col>
  </Row>
  <Divider />



</div>
);
}
}

export default injectIntl(connect(
  state => ({
  uid: state.Auth.user[5],
  member: state.Auth.userAddress,
  locale: state.LanguageSwitcher.language.locale,
  }),
  { changeUserAddress }
)(UserProfile));
