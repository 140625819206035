import React, { Component, lazy } from 'react';
import { Route } from 'react-router-dom';
//import asyncComponent from '../../helpers/AsyncFunc';
import "./dashAppStyle.css";
class AppRouter extends Component {
  render() {
    const { url, location, history} = this.props;
    return (
      <div>
        <Route
          exact={true}
          key=""
          path={`${url}`}
          location={location}
          component={lazy(() => import('../Overview/overview'))}
          history={history}
        />
        <Route
          exact={true}
          key="events"
          path={`${url}/events`}
          location={location}
          component={lazy(() => import('../Products/products'))}
          history={history}
        />
        <Route
          exact={true}
          key="tickets"
          path={`${url}/tickets`}
          location={location}
          component={lazy(() => import('../Tickets/tickets'))}
          history={history}
        />
        <Route
          exact={true}
          key="users"
          path={`${url}/users`}
          location={location}
          component={lazy(() => import('../Controller/controller'))}
          history={history}
        />
        <Route
          exact={true}
          key="contacts"
          path={`${url}/contacts`}
          location={location}
          component={lazy(() => import('../Contacts'))}
          history={history}
        />
        <Route
          exact={true}
          key="settings"
          path={`${url}/settings`}
          location={location}
          component={lazy(() => import('../Settings/settings'))}
          history={history}
        />
        <Route
          exact={true}
          key="links"
          path={`${url}/links`}
          location={location}
          component={lazy(() => import('../Links/links'))}
          history={history}
        />
      </div>
    );
  }
}

export default AppRouter;
