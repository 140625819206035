import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../components/utility/intlMessages';
import actions from '../../redux/languageSwitcher/actions';
import config from './config';
import { Cascader, Tooltip } from 'antd';
const { changeLanguage } = actions;

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
      this.state = {
         text: 'Unselect',
       }
  }
   onChange = (value, selectedOptions) => {
     console.log('value[0]', value[0]);
     this.props.changeLanguage(value[0]);
     };



  render() {
    const { changeLanguage, locale, color, size } = this.props;
  //  const fcolor=color;




    /*
    <div className="themeSwitchBlock">
      <h4>
        <IntlMessages id="languageSwitcher.label" />
      </h4>
      <div className="themeSwitchBtnWrapper">
        {config.options.map(option => {
          const { languageId, icon } = option;
          const customClass =
            languageId === language.languageId
              ? 'selectedTheme languageSwitch'
              : 'languageSwitch';


          return (
            <button width="20px"
              type="button"
              className={customClass}
              key={languageId}
              onClick={() => {
                changeLanguage(languageId);
              }}
            >
              <img src={process.env.PUBLIC_URL + icon} alt="flag" />
            </button>
          );
        })}
      </div>
    </div>
    */

    return (
      <span>
        <Tooltip placement="bottom" title="language">
          <Cascader options={config.options} onChange={this.onChange}>
            <a href="#" style={{ textDecoration: "underline", fontWeight: "400", color: color, fontSize: size }}>{locale}</a>
          </Cascader>
        </Tooltip>
      </span>

    );
  }
}

export default connect(
  state => ({
    ...state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,

  }),
  { changeLanguage }
)(LanguageSwitcher);
