import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import authAction from '../../redux/auth/actions';
import { Input, Row, Col, Divider, Button } from 'antd';
import IntlMessages from '../../components/utility/intlMessages';
import { FormattedMessage, injectIntl } from 'react-intl';
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
const { logout } = authAction;


class ChangeEmail extends Component {
  constructor(props){
      super(props);
      this.handleEmail = this.handleEmail.bind(this);
      this.state = {
        email1:'',
        email2:'',
      }
  }


  handleEmail() {
      const { email1, email2 } = this.state;
      const { locale } = this.props;
      const { formatMessage } = this.props.intl;
      if (!(email1 && email2)) {
        //notification('error', 'Please choose an email');
        notificationIntl('error','not.confirmEmail',formatMessage);
        return;
      }
      if (email1 !== email2) {
        //notification('error', 'Emails are not identical');
        notificationIntl('error','not.unequalEmail',formatMessage);
        return;
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
       }
      if (validateEmail(email1) == false) {
        //notification('error', 'Please provide a valid email address.');
        notificationIntl('error','not.validEmail',formatMessage);
        return;
      }
      //var user = .firebaseCurrentUser();

      FirebaseHelper.updateEmail(email1,locale).then(function() {
        let a = { email: email1 };
        FirebaseHelper.firebaseUpdateDocAttribute('users', this.props.uid, a)
        .then(function() {
          this.props.logout();
          //notification('success', 'Email changed succesfully. Please signin again.');
          notificationIntl('success','not.successEmailchanged',formatMessage);
        }.bind(this)).catch(function(error) {
          if (error) {
           const message = error.message;
           console.log('error:',error.message);
             }
        });

      }.bind(this)).catch(function(error) {
        if (error) {
        const message = error.message;
           if (message !== 'this is undefined') {
           notification('error', message);
           }
      };
      });

  }

  render() {
    const from = { pathname: "/signin" };
    const { redirectToReferrer } = this.state;
    const { locale } = this.props;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    //<PageHeader>Change Password</PageHeader>
    return (
                <div>
                 <p><IntlMessages id="page.resetEmailDescription" /></p>
                  <Row>
                    <Col>
                      <div style={{ maxWidth: "250px" , paddingTop: "20px"}}>
                        <FormattedMessage id="forms.emailNew">
                        { msg => <Input
                          size="large"
                          type="email"
                          placeholder={msg}
                          value={this.state.email1}
                          onChange={event => this.setState({email1: event.target.value})}

                        />}
                        </FormattedMessage>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ maxWidth: "250px", paddingTop: "10px"}}>
                        <FormattedMessage id="forms.emailConfirm">
                        { msg => <Input
                          size="large"
                          type="email"
                          placeholder={msg}
                          value={this.state.email2}
                          onChange={event => this.setState({email2: event.target.value})}
                        />}
                        </FormattedMessage>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <div>
                      <Button type="primary" onClick={this.handleEmail}  style={{ marginTop: "10px" }}>
                        <IntlMessages id="page.butSave" />
                      </Button>
                    </div>
                  </Row>
                </div>
        );
      }
    }




export default injectIntl(connect(
  state => ({
  uid: state.Auth.user[5],
  locale: state.LanguageSwitcher.language.locale,
  }),
  { logout }
)(ChangeEmail));
