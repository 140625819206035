import Enlang from './entries/en_US';
import Delang from './entries/de_DE';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: Enlang,
  de: Delang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.de.data);
export default AppLocale;
