


const options = [
  {
    key: 'dashboard',
    label: 'sidebar.dashboard',
    leftIcon: 'HomeOutlined',
  },
  {
    key: 'events',
    label: 'sidebar.events',
    leftIcon: 'ScheduleOutlined',
  },
  {
    key: 'tickets',
    label: 'sidebar.tickets',
    leftIcon: 'FileProtectOutlined',
  },
/*  {
    key: 'ticketengine',
    label: 'sidebar.ticketupload',
    leftIcon: 'ControlOutlined',
  },*/
  {
    key: 'settings',
    label: 'sidebar.settings',
    leftIcon: 'SettingOutlined',
    },
  {
    key: 'links',
    label: 'sidebar.links',
    leftIcon: 'LinkOutlined',
    },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'TeamOutlined',
    },
  {
    key: 'contacts',
    label: 'sidebar.contacts',
    leftIcon: 'IdcardOutlined',
    },


/*  {
    key: 'companies',
    label: 'sidebar.companies',
    leftIcon: 'KeyOutlined',
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'UserSwitchOutlined',
  },
  {
    key: 'script',
    label: 'sidebar.setupCompany',
    leftIcon: 'UserSwitchOutlined',
  }

  /*,

      {
        key: 'isp',
        label: 'sidebar.isp',
        companyType: 'nwp',
        userType: 'admin',
      },

      {
        key: 'logo',
        label: 'sidebar.logo',
        companyType: 'any',
        userType: 'admin',
      },
      {
        key: 'theme',
        label: 'sidebar.theme',
        companyType: 'any',
        userType: 'admin',
      },*/




];
export default options;
