
const ticketActions = {
  CHANGE_EVENT_ID: 'CHANGE_EVENT_ID',

  changeEventId: (recId) => ({
        type: ticketActions.CHANGE_EVENT_ID,
        recId
      }),

};
export default ticketActions;
