import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

/*
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth(firebaseApp);
onAuthStateChanged(auth, user => {
  // Check for user status
});
*/

import { firebaseConfig } from '../../settings';
import { firebaseFunctions } from '../../settings';

export const fbConfig = firebase;

var app = firebase.initializeApp(firebaseConfig);

app.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

class FirebaseHelper {

  constructor() {
    this.database = app.firestore();
    this.auth = app.auth();
    this.functions = app.functions(firebaseFunctions.location);// -> eur-west1
    this.storage = app.storage();

  }

  sendEmailVerification(locale) {
  //this.auth.useDeviceLanguage();
    this.auth.languageCode = locale;
    return this.auth.currentUser.sendEmailVerification();
  }
  
  httpCallable(name,data) {
     var functionRef = this.functions.httpsCallable(name);
     return functionRef(data);
  }

  createBatch()  {
    return this.database.batch();
  }

  applyActionCode(actionCode) {
    return this.auth.applyActionCode(actionCode);
  }

  checkActionCode(actionCode) {
    return this.auth.checkActionCode(actionCode);
  }

  verifyPasswordResetCode(actionCode) {
    return this.auth.verifyPasswordResetCode(actionCode);
  }

  confirmPasswordReset(actionCode, newPassword) {
    return this.auth.confirmPasswordReset(actionCode, newPassword);
  }

  sendPasswordResetEmail(email,locale) {
  //this.auth.useDeviceLanguage();
  this.auth.languageCode = locale;
  return this.auth.sendPasswordResetEmail(email);
  }

  updateEmail(email,locale){
  //this.auth.useDeviceLanguage();
  this.auth.languageCode = locale;
  return this.auth.currentUser.updateEmail(email);
  }

  sendEmailVerification(locale) {
  //this.auth.useDeviceLanguage();
  this.auth.languageCode = locale;
  return this.auth.currentUser.sendEmailVerification();
  }

  firebaseGetDocEmail(collect, email) {
    var userRef = this.database.collection(collect).where("email", "==", email);
    return userRef.get();
  }

  firebaseGetUsersCid(cid) {
    var userRef = this.database.collection('users').where('deleted_at', '==', null).where("cid", "==", cid).orderBy("lastname");
    return userRef.get();
  }

  firebaseGetCompaniesType(fnp) {
    var userRef = this.database.collection('companies').where('deleted_at', '==', null).where("type", "==", fnp).orderBy("name");
    return userRef.get();
  }

  firebaseGetCompanies() {
    var userRef = this.database.collection('companies').where('deleted_at', '==', null)//.where("cid", "<", cid).where("cid", ">", cid) //.orderBy("name");
    return userRef.get();
  }

  firebaseGetUsersCidLimit1(cid) {
    var userRef = this.database.collection('users').where("cid", "==", cid).limit(1);
    return userRef.get();
  }

  firebaseGetDoc(collect, id) {
    var userRef = this.database.collection(collect).doc(id);
    return userRef.get();
  }

  firebase() {
    return this.database;
  }

  firebaseInsertDoc(collect, id, data) {
    //var userRef =
  return  this.database.collection(collect).doc(id).set(data);
  }

  firebaseInsertDocSub(collect,id,subcollect,data) {
    //var userRef =
  return  this.database.collection(collect).doc(id).collection(subcollect).add(data);
  }

  firebaseInsertDocSubDoc(collect,id,subcollect,subid,data) {
    //var userRef =
  return  this.database.collection(collect).doc(id).collection(subcollect).doc(subid).set(data);
  }

  firebaseInsertDocRand(collect, data) {
    //var userRef =
  return  this.database.collection(collect).add(data);
  }

  firebaseUpdateDoc(collect, id, data) {
    //var userRef =
    this.database.collection(collect).doc(id).update(data);
  }

  firebaseUpdateDocAttribute(collect, id, attributeObj) {
    let b = {merge: true};
    var userRef = this.database.collection(collect).doc(id);
    return userRef.set(attributeObj,b);
  }

  firebaseArrayElementAdd(collect, id, array, data) {
    let a = {};
    a[array] = firebase.firestore.FieldValue.arrayUnion(data);
    var dbRef = this.database.collection(collect).doc(id);
    return dbRef.update(a);
  }

  firebaseDeleteDoc(collect, id) {
    var userRef = this.database.collection(collect).doc(id);
    return userRef.delete();
  }

  firebaseDeleteAttribute(collect, id, attribute) {
  let a = {};
  a[attribute] = firebase.firestore.FieldValue.delete();
  var delRef = this.database.collection(collect).doc(id);
  return delRef.update(a);
  }


  firebaseDeleteEntireDocSUB(collect,id,subcollect,subid) {

    return this.database.collection(collect).doc(id).collection(subcollect).doc(subid).delete();
    }

  firebaseDeleteTicketsWithPid(pid) {

    var jobskill_ref = this.database.collection('tickets').where('pid','==',pid);
    let batch = this.database.batch();

    jobskill_ref.get()
    .then(snapshot => {
    snapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    return batch.commit();
    })
  }
/*
      async function deleteCollection(pid) {
      const collectionRef = this.database.collection('tickets');
      const query = collectionRef.where("pid", "==", pid ).limit(3);

      return new Promise((resolve, reject) => {
        deleteQueryBatch(query, resolve).catch(reject);
      });
    }

    async function deleteQueryBatch(query, resolve) {
      const snapshot = await query.get();

      const batchSize = snapshot.size;
      if (batchSize === 0) {
        // When there are no documents left, we are done
        resolve();
        return 'done';
      } else {
        return 'no tickets to delete!';
      }

      // Delete documents in a batch
      const batch = this.database.batch();
      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Recurse on the next process tick, to avoid
      // exploding the stack.
      process.nextTick(() => {
        deleteQueryBatch(query, resolve);
      });
    }

  }
*/

  firebaseStorageRef() {
    return this.storage.ref();
  }

  firebaseDbDocRef(collection) {
    return this.database.collection(collection).doc();

  }

  firebaseCurrentUser() {
    return this.auth.currentUser;
  }

  firebaseLogin(email,password) {
    return this.auth.signInWithEmailAndPassword(
      email,
      password
    );
  }

  firebaseLoginProvider(provider) {
    return this.auth.signInWithPopup(provider);
  }

  logout() {
    this.auth.signOut().then(function() {
    fbConfig.logout();
    console.log("Sign-out successful");
  }).catch(function(error) {
  console.log(error);
  });
  }

  firebaseCreateUser(email,password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  isAuthenticated() {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        return true;
      } else {
        console.log('false');
        return false;

  }

    });
  }
  resetPassword(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
  createNewRef() {
    return firebase
      .database()
      .ref()
      .push().key;
  }
  processFireStoreCollection(snapshot) {
    let data = {};
    snapshot.forEach(doc => {
      data = {
        ...data,
        [doc.id]: doc.data(),
      };
    });
    return data;
  }

  processFireStoreCollectionII(snapshot) {
    console.log('snapshot',snapshot);
    let data = {};
    let doc = {};
    for(let index in snapshot){
      doc = snapshot[index];
      data = {
        ...data,
        [index]: doc.data(),
      };
    };
    return data;
  }


}






export default new FirebaseHelper();
