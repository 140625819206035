import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import userAction from "../../redux/users/actions";
import authAction from "../../redux/auth/actions";
import FirebaseHelper from "../../helpers/firebase/index";
const { changeUserCompany } = userAction;
const { changeThemeStart } = authAction;
class StartHelperUser extends Component {
  constructor(props){
      super(props);
    //  this.setUserStuff = this.setUserStuff.bind(this);
      this.state = {
      }
  }
  /*
 setUserStuff() {

    const { companies } = this.props;
    this.props.changeUserCompany(this.props.user[0],this.props.user[4]);




    FirebaseHelper.firebaseGetDoc('companies', this.props.user[4]).then(function(doc) {
    if (doc.exists) {
        let company = doc.data();
        //console.log('layoutTheme3',company.layoutTheme);
        if (company.layoutTheme) {
        this.props.changeThemeStart('layoutTheme',company.layoutTheme);
        }
    }

}.bind(this));

  }

  componentDidMount() {
    this.setUserStuff();
  }
*/
  render() {

    return (
      <div></div>
    );
  }
}

const mapStateToProps = (state) => {
 //console.log('firebaseCollections User',state.firestore.ordered);
 //console.log('authUser',state.Auth.user);
 //console.log('isHost',state.Auth.isHost);
 //console.log('is_admin',state.Auth.is_admin);
  return {
   ...state.Auth,
   //companies: state.firestore.ordered.companies ? state.firestore.ordered.companies : [],
  }
}
export default compose(
  connect(mapStateToProps, {
    changeUserCompany,
    changeThemeStart,
  }),
  firestoreConnect((props) => {
    if (props.user[0] == 'member') return []
    if (!props.user[4]) return []
    return [
  //  { collection: 'companies', doc: props.user[4] },
    { collection: 'users', where: [ ['cid', '==', props.user[4] ] ], orderBy: [ 'type' ], orderBy: [ 'lastname' ] },
    { collection: 'products', where: [ ['cid', '==', props.user[4] ] ], orderBy: [ 'dateStart' ] },
    { collection: 'inventory', where: [ ['cid', '==', props.user[4] ] ] },
   ]})
)(StartHelperUser);
