import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConfigProvider, Layout, Tabs, Modal, Button } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import TopbarLanding from "../TopbarLanding/topbarLanding";
import TC from '../../components/terms/termsConditions';
import PP from '../../components/terms/privacyPolicy';
import pci from '../../image/pci.png';
import AppLandingRouter from './AppLandingRouter';
import { siteConfig } from '../../settings';
import { stripeConfig } from '../../settings';
import AppLocale from "../../languageProvider";
import themes from '../../settings/themes';
import PaymentIcons from '../../settings/paymentMethods/icons';

const { TabPane } = Tabs;
const { Content, Footer } = Layout;
const { toggleAll } = appActions;

class AppLanding extends Component {
  constructor() {
    super();
    this.showTC = this.showTC.bind(this);
    this.state = {tc: false};
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(stripeConfig.pk)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
      this.setState({stripe: window.Stripe(stripeConfig.pk)});
      });
    }
  }

  showTC() {
    this.setState({ tc: !this.state.tc});
  }

  render() {
    const { history , isLoggedInMember } = this.props;

    const { url } = this.props.match;
    const { location } = this.props.location;
    const { locale, selectedTheme, height, view } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    /*
    <img height={25} width="auto" src={PaymentIcons.mastercard} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>
    <img height={25} width="auto" src={PaymentIcons.visa} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>
    <img height={25} width="auto" src={PaymentIcons.amex} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>

    */
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes.themedefault}>

              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
              {

                <TopbarLanding  url={url} view={view} history={history}/>
              }
              <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                <Layout style={{ height: height }}>
                  <Content

                    style={{
                      padding: '60px 0 0',
                      flexShrink: '0',
                      background: '#f1f3f6',
                      position: 'relative'
                    }}
                  >
                    <AppLandingRouter url={url} location={location} isLoggedInMember={isLoggedInMember} history={history} />
                  </Content>
                  <Footer
                    style={{
                      background: '#d9d9d9',
                      textAlign: 'center',
                      borderTop: '1px solid #d9d9d9',
                      fontSize: '12px',
                      height: '60px',
                      paddingTop: '3px',
                      paddingBottom: '0px',

                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between" }}>
                      <div>

                        {siteConfig.footerText}&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={this.showTC} >Terms & Privacy</span>
                      </div>
                      <div>
                      </div>
                      { view !== "MobileView" && view !== "TabView" &&
                      <div style={{ height: '100%' }}>
                        
                        <img height={56} alt='pci' width="auto" src={pci} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px', marginLeft: '30px'}} />
                      </div>
                      }
                    </div>

                  </Footer>
                  <Modal

                    visible={this.state.tc}
                    closable={true}
                    onOk={this.showTC}
                    onClose={this.showTC}
                    onCancel={this.showTC}
                    footer={[
                      <Button type='primary' key="back" onClick={this.showTC}>
                        Ok
                      </Button>]}

                    width='800px'
                    zIndex='2000'
                    >
                    <div style={{  display: "flex",flexDirection: "column", justifyContent: "space-around", alignItems: 'center' }}>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Terms and Conditions" key="1">
                          <TC/>
                        </TabPane>
                        <TabPane tab="Privacy Policy" key="2">
                          <PP/>
                        </TabPane>
                      </Tabs>
                    </div>
                  </Modal>
              </Layout>
            </Layout>
          </Layout>

      </ThemeProvider>
      </IntlProvider>
      </ConfigProvider>
    );
  }
}



export default connect(

  state => ({
        ...state.Auth,
        auth: state.Auth,
        locale: state.LanguageSwitcher.language.locale,
        isLoggedInMember: state.Auth.isLoggedInMember,
        height: state.App.height,
        view: state.App.view
  }),
  { toggleAll }
)(AppLanding);
