import clone from "clone";
import actions from "./actions";

const initState = {
  loadingInitData: false,
  view: "gridView",
  viewTopbarCart: false,
  viewTopbarPaymentMethods: false,
  viewTopbarUserProfile: false,
  viewTopbarCompanyProfile: false,
  viewTopbarCheckoutProcessing: false,
  productQuantity: [],
  products: {}
};
export default (state = initState, action) => {
  switch (action.type) {
    case actions.INIT_DATA:
      return {
        ...state,
        loadingInitData: true,
        productQuantity: action.payload.productQuantity,
        products: action.payload.products
      };
    case actions.CHANGE_VIEW:
      return {
        ...state,
        view: action.view
      };
    case actions.VIEW_TOPBAR_CART:
      return {
        ...state,
        viewTopbarCart: action.viewTopbarCart
      };
    case actions.VIEW_TOPBAR_PAYMENT_METHODS:
      return {
        ...state,
        viewTopbarPaymentMethods: action.viewTopbarPaymentMethods
      };
    case actions.VIEW_TOPBAR_USER_PROFILE:
      return {
        ...state,
        viewTopbarUserProfile: action.viewTopbarUserProfile
      };
    case actions.VIEW_TOPBAR_COMPANY_PROFILE:
      return {
        ...state,
        viewTopbarCompanyProfile: action.viewTopbarCompanyProfile
      };
    case actions.VIEW_TOPBAR_CHECKOUT_PROCESSING:
      return {
        ...state,
        viewTopbarCheckoutProcessing: action.viewTopbarCheckoutProcessing
      };
    case actions.UPDATE_DATA:
      return {
        ...state,
        products: clone(action.products),
        productQuantity: clone(action.productQuantity)
      };
    default:
      return state;
  }
};
