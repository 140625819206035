import actions from "./actions";
import { topbarTheme, sidebarTheme, layoutTheme} from '../../settings/index';



const initState = { isLoggedIn: false,
                    isLoggedInMember: false,
                    isAdmin: false,
                    modalSignup:false,
                    modalLogin: false,
                    profilePicture: '',
                    country: '',
                    logo: '',
                    user: [],
                    userAddress: '',
                    company: '',
                    topbarTheme: topbarTheme,
                    sidebarTheme: sidebarTheme,
                    layoutTheme: layoutTheme
 };

export default function authReducer(
  state = initState, action  )
  {switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };

    case actions.LOGIN_SUCCESS_MEMBER:
      return {
        ...state,
        isLoggedInMember: true,
      };

    case actions.TOGGLE_ADMIN:
      return {
        ...state,
        isAdmin: true,
      };

    case actions.TOGGLE_LOGIN:
      return {
        ...state,
        modalLogin: action.bool,
      };

    case actions.TOGGLE_SIGNUP:
      return {
        ...state,
        modalSignup: action.bool,
      };

    case actions.ADD_USER_CREDENTIALS:
     return {
      ...state,
      user: state.user.concat([action.payload])
     }

    case actions.CHANGE_AVATAR:
     return {
      ...state,
      profilePicture:  action.payload
      }

    case actions.CHANGE_THEME:
      return {
        ...state,
        [action.attribute]: action.theme
      }

    case actions.FETCH_USER:
      return {
       ...state,
      }

    case actions.CHANGE_COUNTRY:
      return {
       ...state,
      country: action.country
      }

    case actions.USER_ADDRESS:
      return {
       ...state,
      userAddress: action.userAddress
      }
      
    case actions.SET_COMPANY:
      return {
        ...state,
      company: action.company
      }

    case actions.LOGOUT:
      //return initState;
      return {
       ...state,
      isLoggedIn: false,
      isLoggedInMember: false,
      isAdmin: false,
      profilePicture: '',
      logo: '',
      company: '',
      user: []
     }
    default:
      return state;
  }
}
