import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { Input, Divider, Button } from "antd";
import IntlMessages from '../../components/utility/intlMessages';
import FirebaseHelper from "../../helpers/firebase/index";
import { notification } from '../../components/index';

export default class extends Component {
  constructor(props){
      super(props);
      this.triggerSignin = this.triggerSignin.bind(this);
      this.state = {
        email:'',

      }
  }

  handlePW = () => {
      const { email } = this.state;
      FirebaseHelper.resetPassword(email).then(function() {
        notification('success', 'Email with further instructions sent');
        return;
      }).catch(function(error) {
        const message = error.message;
        notification('error', message);
        return;
      });
  }

  triggerSignin() {
    this.props.handleLogin();
  }

  render() {
    const { logoDark } = this.props;
    return (
      <div>
        <div>
          <img height={40} src={logoDark} />
        </div>
        <Divider />
        <div>
          <p>
            <IntlMessages id="page.forgetPassDescription" />
          </p>
        </div>

          <div style={{ marginTop: "5px" }}>
            <Input value={this.state.email} size="large" placeholder="Email" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} onChange={event => this.setState({email: event.target.value})}/>
          </div>

          <div style={{ marginTop: "15px" }}>
            <Button type="primary" size="large" onClick={this.handlePW} block>
              <IntlMessages id="page.sendRequest" />
            </Button>
          </div>
          <Divider />
          <div>
            <label>
              <IntlMessages id="page.forgetPassToSignIn" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignin}>
              <IntlMessages id="page.forgetPassToSignInLink" />
            </label>
          </div>
        </div>
    );
  }
}
