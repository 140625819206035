import Ch from '../../image/flag/24x24/ch.png';
import De from '../../image/flag/24x24/de.png';
import At from '../../image/flag/24x24/at.png';
import It from '../../image/flag/24x24/it.png';
import Fr from '../../image/flag/24x24/fr.png';
import Li from '../../image/flag/24x24/li.png';
import Es from '../../image/flag/24x24/es.png';
import Pt from '../../image/flag/24x24/pt.png';
import Nl from '../../image/flag/24x24/nl.png';
import Be from '../../image/flag/24x24/be.png';
import Lu from '../../image/flag/24x24/lu.png';
import No from '../../image/flag/24x24/no.png';
import Se from '../../image/flag/24x24/se.png';
import Fi from '../../image/flag/24x24/fi.png';
import Dk from '../../image/flag/24x24/dk.png';
import Ca from '../../image/flag/24x24/ca.png';
import Us from '../../image/flag/24x24/us.png';
import Au from '../../image/flag/24x24/au.png';
import Nz from '../../image/flag/24x24/nz.png';
import Gb from '../../image/flag/24x24/gb.png';
var flags =  {}
                flags.ch = Ch;
                flags.de = De;
                flags.at = At;
                flags.it = It;
                flags.fr = Fr;
                flags.li = Li;
                flags.no = No;
                flags.se = Se;
                flags.fi = Fi;
                flags.dk = Dk;
                flags.es = Es;
                flags.pt = Pt;
                flags.nl = Nl;
                flags.be = Be;
                flags.Lu = Lu;
                flags.Li = Li;

                flags.gb = Gb;
                flags.ca = Ca;
                flags.us = Us;
                flags.au = Au;
                flags.nz = Nz;

export default flags;
