import config, { getCurrentLanguage } from '../../containers/LanguageSwitcher/config';
import detectBrowserLanguage from 'detect-browser-language';
import actions from './actions';

let a = detectBrowserLanguage();
a = a.substring(0, 3);

const initState = {
  isActivated: false,
  language: getCurrentLanguage(a),
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated
      };
    case actions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    default:
      return state;
  }
}
